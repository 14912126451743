<template>
  <div class="container-fluid">
    <h3 class="title">Параметры участков и вершин</h3>
    <p class="subtitle">Для каждой пары вершин нужно задать:</p>
    <ul class="list">
      <li class="list-item">
        Номер участка, который их соединяет (пусто, если вершины не связаны);
      </li>
      <li class="list-item">
        Расстояние между вершинами в метрах (пусто, если вершины не связаны);
      </li>
      <li class="list-item">
        Скорость сигнала в метрах в секунду (пусто, если вершины не связаны).
      </li>
    </ul>

    <div class="mb-3 d-flex align-items-center">
      <div class="me-3 flex-grow-1">
        <label for="powerlineId" class="form-label">ЛЭП</label>
        <b-form-select v-model="selectedPowerLine" :options="formattedPowerLines" id="powerlineId" class="form-control"
          :class="{ 'is-invalid': errors?.powerlineId }" @change="onPowerLineChange"></b-form-select>
      </div>
      <div class="me-3 flex-grow-1">
        <label for="vertexCount" class="form-label">
          Введите общее количество вершин
        </label>
        <input v-model.number="vertexCount" @input="handleVertexCountChange" type="number" id="vertexCount"
          class="form-control" min="2" step="1" placeholder="Введите количество вершин"
          :disabled="!selectedPowerLine" />
      </div>

      <div v-if="isLoading" class="spinner-border text-primary ms-3" role="status">
        <!-- <span class="visually-hidden">Loading...</span> -->
      </div>
    </div>
    <div class="mt-3">
      <button @click="saveData" class="btn btn-primary" :disabled="!selectedPowerLine">
        Сохранить
      </button>
    </div>

    <div v-if="matrixSize > 0 && selectedPowerLine" class="table-responsive"
      style="height: calc(100vh - 450px); width: 100%; overflow: auto">
      <table class="table table-bordered" style="table-layout: fixed">
        <thead>
          <tr>
            <th class="fixed-cell">Вершины</th>
            <th class="fixed-cell" v-for="i in matrixSize" :key="'header-' + i">
              {{ i }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="j in matrixSize" :key="'row-' + j">
            <td class="fixed-cell">
              {{ j }}
            </td>
            <td v-for="i in matrixSize" :key="'cell-' + j + '-' + i">
              <div v-if="i < j">
                <div>{{ j }}-{{ i }}</div>
                <div>
                  <label>Расстояние:</label>
                  <input :value="getSegment(j, i).distance" @input="
                    updateSegment(j, i, 'distance', $event.target.value)
                    " type="number" placeholder="Введите расстояние" class="form-control" />
                </div>
                <div>
                  <label>Скорость:</label>
                  <input :value="getSegment(j, i).speed" @input="updateSegment(j, i, 'speed', $event.target.value)"
                    type="number" placeholder="Введите скорость" class="form-control" />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "MatrixBuilder",
  data() {
    return {
      teamId: ApiService.teamData.value,
      vertexCount: 2,
      isLoading: false,
      powerlines: [],
      selectedPowerLine: null,
      errors: {},
      matrixData: {},
      maxSegmentId: 0, // Отслеживаем максимальный ID сегмента
      removedSegments: [], // Храним удаленные сегменты
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Участки") },
    ]);
    await this.loadPowerLines();
  },
  computed: {
    matrixSize() {
      return this.vertexCount;
    },
    formattedPowerLines() {
      return [
        { value: null, text: "Выберите ЛЭП", disabled: true },
        ...this.powerlines.map((line) => ({
          value: line.id,
          text: line.name,
        })),
      ];
    },
  },
  methods: {
    async loadPowerLines() {
      this.isLoading = true;
      try {
        const templateResponse = await axios.get(`api/oscillogram/powerline`, {
          params: {
            teamId: this.teamId,
          }
        });
        this.powerlines = Object.values(templateResponse.data);
        this.removedSegments = []; // Очищаем удаленные сегменты при загрузке новых данных
      } catch (error) {
        this.onError(error);
      } finally {
        this.isLoading = false;
      }
    },

    async onPowerLineChange() {
      if (this.selectedPowerLine) {
        this.isLoading = true;
        try {
          const segmentResponse = await this.getSegmentsForPowerLine(
            this.selectedPowerLine
          );

          this.matrixData = {};
          this.maxSegmentId = 0; // Сбрасываем максимальный ID при смене ЛЭП
          this.removedSegments = []; // Сбрасываем удаленные сегменты при смене ЛЭП

          Object.entries(segmentResponse.segments).forEach(([id, segment]) => {
            const segmentId = parseInt(id, 10);
            this.maxSegmentId = Math.max(this.maxSegmentId, segmentId);

            this.$set(this.matrixData, segmentId, {
              vertexAid: segment.vertexAid,
              vertexBid: segment.vertexBid,
              distance: segment.distance || 0,
              speed: segment.speed || 0,
            });
          });

          const vertexIds = Object.values(segmentResponse.segments).flatMap(
            (segment) => [segment.vertexAid, segment.vertexBid]
          );
          this.vertexCount = vertexIds.length > 0 ? Math.max(...vertexIds) : 2;
        } catch (error) {
          this.onError(error);
        } finally {
          this.isLoading = false;
        }
      }
    },

    handleVertexCountChange() {
      const newVertexCount = this.vertexCount;

      console.log("VertexCount: " + newVertexCount)


      if (newVertexCount < this.matrixSize) {
        console.log("/ Удаляем вершины и связанные с ними сегменты: " + newVertexCount)

        // Удаляем вершины и связанные с ними сегменты
        for (let i = this.matrixSize; i > newVertexCount; i--) {
          this.removeVertex(i);
        }
      }
    },

    async getSegmentsForPowerLine(powerlineId) {
      const params = {
        powerlineId,
      };
      const response = await axios.get("api/oscillogram/segment", { params });
      return response.data;
    },

    getSegment(row, col) {
      const existingSegment = Object.values(this.matrixData).find(
        (item) =>
          (item.vertexAid === row && item.vertexBid === col) ||
          (item.vertexAid === col && item.vertexBid === row)
      );

      return existingSegment || { distance: 0, speed: 0 };
    },

    updateSegment(row, col, field, value) {
      let segmentId = this.findEntryId(row, col);

      const numericValue = parseFloat(value);
      const validValue = isNaN(numericValue) ? 0 : numericValue;

      if (segmentId) {
        // Сегмент существует, обновляем его
        this.$set(this.matrixData[segmentId], field, validValue);
      } else {
        // Сегмента не существует, создаем новый
        segmentId = this.getNextId();
        this.$set(this.matrixData, segmentId, {
          vertexAid: row,
          vertexBid: col,
          distance: field === "distance" ? validValue : 0,
          speed: field === "speed" ? validValue : 0,
        });
      }
    },

    findEntryId(row, col) {
      for (const [id, segment] of Object.entries(this.matrixData)) {
        if (
          (segment.vertexAid === row && segment.vertexBid === col) ||
          (segment.vertexAid === col && segment.vertexBid === row)
        ) {
          return id;
        }
      }
      return null;
    },

    getNextId() {
      this.maxSegmentId += 1;
      return this.maxSegmentId.toString();
    },

    removeVertex(vertexId) {
      Object.entries(this.matrixData).forEach(([id, item]) => {
        if (item.vertexAid === vertexId || item.vertexBid === vertexId) {
          this.removedSegments.push({
            segmentPosition: `${item.vertexAid}-${item.vertexBid}`,
          });
          this.$delete(this.matrixData, id);
        }
      });
    },

    saveData() {
      // Перед отправкой данных удалим сегменты, связанные с удаленными вершинами
      const filteredSegments = {};

      Object.entries(this.matrixData).forEach(([id, segment]) => {
        if (
          segment.vertexAid <= this.vertexCount &&
          segment.vertexBid <= this.vertexCount
        ) {
          filteredSegments[id] = segment;
        }
      });

      console.log("Отправляемые данные:", filteredSegments);

      // Отправляем удаление сегментов, которые были удалены
      this.removedSegments.forEach((segment) => {
        console.log(`Отправляем запрос на удаление сегмента ${segment.segmentPosition}`);
        axios
          .delete("api/oscillogram/segment/delete", {
            data: {
              powerlineId: this.selectedPowerLine,
              segmentPosition: segment.segmentPosition,
            },
          })
          .then((response) => {
            console.log(
              `Сегмент ${segment.segmentPosition} успешно удален`,
              response
            );
          })
          .catch((error) => {
            console.error(
              `Ошибка при удалении сегмента ${segment.segmentPosition}`,
              error
            );
          });
      });

      // Отправляем обновленные данные
      axios
        .post("api/oscillogram/segment/grid", {
          powerlineId: this.selectedPowerLine,
          segments: filteredSegments,
        })
        .then((response) => {
          console.log("Данные успешно сохранены", response);
        })
        .catch((error) => {
          console.error("Ошибка при сохранении данных", error);
        });
    },

    onError(error) {
      let message = this.$t("COMMON.UNKNOWN_ERROR");

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message = error.response.data.message;
      } else if (error.message) {
        message = error.message;
      } else if (error.request) {
        message = this.$t("COMMON.NETWORK_ERROR");
      }

      this.$bvToast.toast(message, {
        title: this.$t("COMMON.ERROR"),
        variant: "danger",
        autoHideDelay: 5000,
      });

      this.isLoading = false;
    },
  },
};

</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  vertical-align: top;
}

button {
  margin-left: 5px;
  cursor: pointer;
}

div {
  margin: 2px 0;
}

.container-fluid {
  max-width: 100%;
  margin: 0 auto;
  padding: 15px;
}

.table-responsive {
  max-width: 100%;
  overflow: auto;
  padding: 15px;
}

.table {
  margin-top: 20px;
  text-align: center;
  table-layout: fixed;
}

.fixed-cell {
  width: 150px;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  border: 1px solid #3333331c;
}

.fixed-cell input.form-control {
  width: 130px;
  height: 30px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 0.2em;
}

.table-bordered {
  border-color: #333 !important;
}

.table thead th,
.table tbody td {
  border-color: #333 !important;
  background-color: #f9f9f9;
}

.table thead th {
  background-color: #dddddd9c;
  font-weight: bold;
}

.table tbody td {
  background-color: #fff;
}

.form-select {
  width: 100%;
  height: 38px;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.subtitle {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 0.75rem;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  font-size: 1.125rem;
  color: #666;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }

  &:before {
    content: '•';
    color: #999;
    font-weight: bold;
    display: inline-block;
    width: 1rem;
  }
}
</style>
