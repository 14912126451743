<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-9">
            <b-form-select size="sm" v-model="filterMyDevices" v-if="user.grants.includes('SYSTEM:ADMIN')">
              <option :value="true">My devices</option>
              <option :value="false">{{ $t("COMMON.ALL_DEVICES") }}</option>
            </b-form-select>

            <b-form-input size="sm" :aria-label="$t('COMMON.NAME')" :placeholder="$t('COMMON.NAME')"
              v-model="filterName" trim></b-form-input>

            <b-input-group-append>
              <b-button size="sm" text="Search" variant="success" @click="onFilterSearch">{{ $t("COMMON.SEARCH")
                }}</b-button>
              <b-button size="sm" text="Search" variant="secondary" @click="onFiltersClear">{{ $t("COMMON.CLEAR")
                }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table id="table" striped bordered :items="response.data" @sort-changed="sortingChanged" no-local-sorting
          responsive selectable select-mode="single" :fields="fields" @row-selected="onRowsSelected"></b-table>

        <b-dropdown id="dropdown-show-by" :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage" class="m-md-2" offset="-50"
          style="float: right">
          <b-dropdown-item v-for="count in showPerPageArray" :key="count" @click="showPerPageChanged(count)">{{ count
            }}</b-dropdown-item>
        </b-dropdown>

        <div v-bind:style="response.data.length === 0 || itemsTotal < perPage
          ? 'display:none;'
          : null
          ">
          <b-pagination v-model="currentPage" :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null" :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate" :size="!calculate ? 'lg' : null" :per-page="perPage" @change="onPageSelected"
            :total-rows="itemsTotal" aria-controls="table">
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>
    <b-modal id="modal-delete-confirmation" :title="$t('COMMON.CONFIRMATION')" @ok="onDelete">
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedItem.name,
          })
        }}
      </p>
    </b-modal>

    <b-modal id="modal-command" :title="$t('DEVICE.EXECUTE_DEVICE_COMMAND')" @ok="executeCommand"
      :ok-disabled="selectedCommand.value == null" size="xl" static body-class="position-static">
      <b-overlay :show="executeCommandOverlay" :rounded="true" no-wrap></b-overlay>
      <b-card-body>
        <b-form-group label-cols-lg="2" :label="$t('COMMON.DEVICE')" label-for="device">
          <CompactSuggestion v-model="selectedDevice.id" :disabled="!!selectedCatalogue.id"
            @hit="(val) => setDevice(val.id, val.name, val.tags.typeId)"
            suggestion-processor="deviceSuggestionProcessor" :placeholder="$t('COMMON.TYPE_DEVICE')"
            ref="sendCommandDeviceSuggestionProcessor" size="sm">
          </CompactSuggestion>
        </b-form-group>

        <b-form-group label-cols-lg="2" :label="$t('COMMON.CATALOGUE')" label-for="catalogue">
          <CompactSuggestion ref="sendCommandCatalogueSuggestionProcessor" v-model="selectedCatalogue.id"
            :disabled="!!selectedDevice.id" @hit="onCatalogueSelected" :placeholder="$t('COMMON.TYPE_CATALOGUE')"
            size="sm" suggestion-processor="catalogueSuggestionProcessor">
          </CompactSuggestion>
        </b-form-group>

        <b-form-group id="field-command" label-cols-lg="2" :label="$t('COMMON.COMMAND')" label-for="field-command">
          <b-form-select v-model="selectedCommand.value" :options="commands" v-on:change="onCommandSelected">
            <template v-slot:first>
              <option :value="null">{{ $t("DEVICE.SELECT_COMMAND") }}</option>
            </template>
          </b-form-select>
        </b-form-group>

        <template v-for="(item, i) in selectedCommand.parameters">
          <b-form-group v-bind:key="i" id="field-password-gr" label-cols-lg="2" v-bind:label="item.name"
            label-for="field-p2">
            <b-form-input id="field-p2" v-model="item.defaultValue"></b-form-input>
          </b-form-group>
        </template>
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import CompactSuggestion from "../component/CompactSuggestion";

export default {
  name: "skat-devices",
  components: { CompactSuggestion },
  data() {
    return {
      fields: [
        {
          key: "id",
          formatter: Common.idObjectFormatter,
          sortingKey: "id",
          sortable: false,
        },
        {
          key: "name",
          label: this.$t("COMMON.NAME"),
          sortingKey: "name",
          sortable: true,
        },
        {
          key: "active",
          label: this.$t("COMMON.ACTIVE"),
          formatter: Common.booleanFormatter,
          sortingKey: "active",
          sortable: true,
        },
        {
          key: "publicAvailable",
          label: this.$t("DEVICE.PUBLIC"),
          formatter: Common.booleanFormatter,
          sortingKey: "publicAvailable",
          sortable: true,
        },
        {
          key: "lastDataReceivedDt",
          label: this.$t("DEVICE.LAST_DATA_RECEIVED"),
          formatter: Common.idObjectFormatter,
          sortingKey: "lastDataReceivedDt",
          sortable: true,
        },
        {
          key: "created",
          label: this.$t("CHECKOUT.CREATION_DATE"),
          formatter: Common.idObjectFormatter,
          sortingKey: "created",
          sortable: true,
        },
      ],

      filterName: "",
      response: {
        data: [],
        totalCount: 1,
      },
      showPerPageArray: [10, 50, 100],

      getList: {
        resource: "/api/device",
        requestType: "GET",
        requestParams: {
          teamId: ApiService.teamData.value,
          deviceTypeId: "297e9ce2-9134-4004-b67f-21c1683aaede"
        },
      },

      getCommands: {
        resource: "/api/command",
        requestType: "GET",
        requestParams: { enrich: true, calculate: false },
      },

      currentPage: 1,
      perPage: 10,

      showTableOverlay: true,
      calculate: false,
      selectedItem: null,
      filterMyDevices: true,
      executeCommandOverlay: false,

      commands: [],
      selectedCommand: {
        value: null,
        text: null,
        parameters: [],
      },

      save: {
        resource: "/api/command/save",
        requestType: "POST",
        requestParams: {},
      },

      selectedDevice: { name: null, id: null },
      selectedCatalogue: { name: null, id: null },
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      defaultTeam: (state) => state.team.defaultTeam
    }),
    itemsTotal() {
      return this.response.totalCount;
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.SCAT_DEVICES") }]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("ACTIONS.CREATE_NEW"),
        action: this.onCreateNewAction,
        icon: "fas fa-plus",
      },
      {
        label: this.$t("ACTIONS.EDIT"),
        action: this.onEditAction,
        disabled: () => this.selectedItem === null || this.showTableOverlay,
        icon: "fas fa-edit",
      },
      {
        label: this.$t("ACTIONS.DELETE"),
        action: this.onDeleteAction,
        disabled: () => this.selectedItem === null || this.showTableOverlay,
        icon: "fas fa-trash-alt",
      },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
      {
        label: this.$t("COMMON.COMMAND"),
        action: this.showCommandModal,
        icon: "fas fa-terminal",
      },

    ]);
  },

  created() {
    this.getList.requestParams.size = this.perPage;
    this.getList.requestParams.teamId = this.defaultTeam.id;
    this.loadList();
  },

  methods: {
    loadList() {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = {
            data: response.content,
            totalCount: response.totalElements
          };
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    onPageSelected(index) {
      this.getList.requestParams.page = (index - 1);
      this.loadList();
    },

    onRowsSelected(items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];
        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    onCreateNewAction() {
      this.$router.push({ name: "scat-device" });
    },

    onDeleteAction() {
      this.$bvModal.show("modal-delete-confirmation");
    },

    onEditAction() {
      this.$router.push({
        name: "scat-device",
        params: { id: this.selectedItem.id },
      });
    },

    onReloadAction() {
      this.getList.requestParams.page = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onDelete() {
      const del = {
        resource: "/api/device/" + this.selectedItem.id + "/delete",
        requestType: "DELETE",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
          this.currentPage = 1;
          this.getList.requestParams.page = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    showPerPageChanged(count) {
      this.currentPage = 1;
      this.getList.requestParams.page = 0;
      this.getList.requestParams.size = count;
      this.perPage = count;
      this.loadList();
    },

    onError(response) {
      if (response && response.config) response = response.data;
      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    sortingChanged(ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";
      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.page = 0;
      this.loadList();
    },

    onFilterSearch() {
      this.currentPage = 1;
      this.getList.requestParams.page = 0;
      this.getList.requestParams["name"] =
        this.filterName.length > 0 ? this.filterName : null;
      this.getList.requestParams["teamId"] = this.filterMyDevices
        ? ApiService.teamData.value
        : null;
      this.loadList();
    },

    onFiltersClear() {
      this.currentPage = 1;
      this.getList.requestParams.page = 0;
      this.getList.requestParams.size = this.perPage;
      delete this.getList.requestParams["name"];
      this.filterName = "";
      this.loadList();
    },

    showCommandModal() {
      this.commands = [];
      this.selectedCommand = { value: null, text: null, parameters: [] };
      this.$refs.sendCommandCatalogueSuggestionProcessor.clearValue();
      if (this.selectedItem != null) {
        this.setDevice(
          this.selectedItem.id,
          this.selectedItem.name,
          this.selectedItem.typeId
        );
      } else {
        this.$refs.sendCommandDeviceSuggestionProcessor.clearValue();
      }
      this.$bvModal.show("modal-command");
    },

    setDevice(id, name, deviceTypeId) {
      this.executeCommandOverlay = true;
      this.selectedDevice.id = id;
      this.selectedDevice.name = name;
      this.selectedCommand = { value: null, text: null, parameters: [] };
      this.getCommands.requestParams.deviceTypeId = deviceTypeId;
      this.selectedCatalogue.id = null;
      this.selectedCatalogue.name = null;
      this.commands = [];
      this.$store
        .dispatch(API_REQUEST, this.getCommands)
        .then((response) => {
          if (response.data.length > 0) {
            for (const value of response.data) {
              this.commands.push({
                value: value.id,
                text: value.name,
                parameters: value.commandProperties,
              });
            }
            this.$refs.sendCommandDeviceSuggestionProcessor.setText(
              this.selectedDevice.name
            );
          } else {
            this.$bvToast.toast(this.$t("DEVICE.COMMANDS_NOT_FOUND"), {
              title: this.$t("COMMON.WARNING"),
              variant: "warning",
              autoHideDelay: 5000,
            });
          }
        })
        .catch(this.onError)
        .finally(() => {
          this.executeCommandOverlay = false;
        });
    },

    executeCommand() {
      const request = this.save;
      let properties = {};
      if (this.selectedCommand.parameters != null) {
        for (const val of this.selectedCommand.parameters) {
          properties[val.code] = val.defaultValue;
        }
      }
      request.dto = this.selectedCommand;
      request.dto.properties = properties;
      request.dto.commandId = this.selectedCommand.value;
      request.dto.deviceId = this.selectedDevice.id;
      request.dto.catalogueId = this.selectedCatalogue.id;
      this.$store
        .dispatch(API_REQUEST, request)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
      this.commands = [];
      this.selectedCommand = { text: null, value: null, parameters: [] };
      this.$bvModal.hide("modal-command");
    },

    onCommandSelected() {
      let item = this.selectedCommand;
      this.selectedCommand = { text: null, value: null, parameters: [] };
      for (const com of this.commands) {
        if (item.value == com.value) {
          this.selectedCommand = {
            text: com.text,
            value: com.value,
            parameters: com.parameters,
          };
          break;
        }
      }
    },

    onCatalogueSelected(val) {
      let com = JSON.parse(val.tags.commands);
      this.commands = [];
      if (com != null && com.length > 0) {
        this.selectedCommand = { value: null, text: null, parameters: [] };
        for (const value of com) {
          this.commands.push({
            value: value.id,
            text: value.name,
            parameters: value.commandProperties,
          });
        }
        this.selectedDevice.id = null;
        this.selectedDevice.name = null;
        this.selectedCatalogue.id = val.id;
      } else {
        this.$bvToast.toast(this.$t("DEVICE.COMMANDS_NOT_FOUND"), {
          title: this.$t("COMMON.WARNING"),
          variant: "warning",
          autoHideDelay: 5000,
        });
      }
    },
  },
};
</script>