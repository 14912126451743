<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <div v-if="readOnly" class="alert alert-light alert-elevate" role="alert">
          <div class="alert-text">
            <p>
              {{ $t("DEVICE.OTHER_TEAM_PUBLIC_DEVICE") }}
            </p>
          </div>
        </div>
        <b-tabs content-class="mt-3" pills @activate-tab="tabChanged"
          v-if="dto.typeId !== '297e9ce2-9134-4004-b67f-21c1683aaede'">
          <b-tab :title="$t('COMMON.TAB_MAIN')" active>
            <b-card-body>
              <b-form-group id="field-name" label-cols-lg="2" :label="$t('COMMON.NAME')" label-for="field-name-input">
                <b-form-input id="field-name-input" :state="validateDtoState('name')" v-model="dto.name"
                  v-bind:disabled="readOnly"></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback" :state="validateDtoState('name')">
                  {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group v-if="dto.id != null" id="field-type-desc" label-cols-lg="2" :label="$t('COMMON.TYPE')"
                label-for="field-type-desc">
                <b-form-input v-bind:disabled="dto.id != null" id="field-type-desc"
                  v-model="dto.typeName"></b-form-input>
              </b-form-group>

              <b-form-group v-if="dto.id == null" id="field-type" label-cols-lg="2" :label="$t('COMMON.TYPE')"
                label-for="field-type">
                <b-form-select v-model="dto.typeId" :state="validateDtoState('typeId')" :options="deviceTypes"
                  v-on:change="onDeviceTypeSelected" v-bind:disabled="readOnly">
                  <template v-slot:first>
                    <option :value="null" disabled>
                      {{ $t("DEVICE.SELECT_DEVICE_TYPE") }}
                    </option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback id="input-1-live-feedback" :state="validateDtoState('typeId')">
                  {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="alert alert-light alert-elevate" role="alert"
                v-if="dto.typeId != null && description != null">
                <div class="alert-text">
                  <p style="white-space: pre">{{ description }}</p>
                </div>
              </div>

              <b-form-group id="field-phoneNumber" label-cols-lg="2" :label="$t('DEVICE.PHONE_NUMBER')"
                label-for="field-phoneNumber-input">
                <b-form-input id="field-phoneNumber-input" v-model="dto.phoneNumber"
                  v-bind:disabled="readOnly"></b-form-input>
              </b-form-group>

              <b-form-group id="field-imei" label-cols-lg="2" :label="$t('DEVICE.IMEI')" label-for="field-imei-input">
                <b-form-input id="field-imei-input" v-model="dto.imei" v-bind:disabled="readOnly"></b-form-input>
              </b-form-group>

              <b-form-group id="field-password" label-cols-lg="2" :label="$t('AUTH.INPUT.PASSWORD')"
                label-for="field-password-input">
                <b-form-input id="field-password-input" v-model="dto.password"
                  v-bind:disabled="readOnly"></b-form-input>
              </b-form-group>

              <template v-if="dto.locationManual">
                <b-form-group id="field-lat" label-cols-lg="2" :label="$t('COMMON.LATITUDE')"
                  label-for="field-lat-input">
                  <b-form-input id="field-lat-input" v-model="dto.lat" disabled></b-form-input>
                </b-form-group>

                <b-form-group id="field-lng" label-cols-lg="2" :label="$t('COMMON.LONGITUDE')"
                  label-for="field-lng-input">
                  <b-form-input id="field-lng-input" v-model="dto.lng" disabled></b-form-input>
                </b-form-group>

                <b-form-group id="field-altitude" label-cols-lg="2" :label="$t('COMMON.ALTITUDE')"
                  label-for="field-altitude-input">
                  <b-form-input id="field-altitude-input" v-model="dto.altitude"
                    v-bind:disabled="readOnly"></b-form-input>
                </b-form-group>

                <b-button variant="primary" v-on:click="onShowModal" v-if="!readOnly">
                  {{ $t("DEVICE.CHOOSE_COORDINATES") }}
                </b-button>
              </template>

              <b-form-group id="field-active" label-cols-lg="2" :label="$t('COMMON.ACTIVE')"
                label-for="checkbox-active-input">
                <b-form-checkbox id="checkbox-active-input" v-model="dto.active" name="checkbox-attachment" class="pt-2"
                  switch size="lg" v-bind:disabled="readOnly">
                </b-form-checkbox>
              </b-form-group>

              <b-form-group id="field-public" v-if="user.grants.includes('SYSTEM:ADMIN')" label-cols-lg="2"
                :label="$t('DEVICE.PUBLIC')" label-for="checkbox-public-input">
                <b-form-checkbox id="checkbox-public-input" v-model="dto.publicAvailable" name="checkbox-attachment"
                  class="pt-2" switch size="lg" v-bind:disabled="readOnly">
                </b-form-checkbox>
              </b-form-group>

              <b-form-group id="field-forecast" label-cols-lg="2" :label="$t('DEVICE.FORECAST')"
                label-for="checkbox-forecast-input">
                <b-form-checkbox id="checkbox-forecast-input" v-model="dto.forecast" name="checkbox-forecast"
                  class="pt-2" switch size="lg" v-bind:disabled="readOnly">
                </b-form-checkbox>
              </b-form-group>
            </b-card-body>
          </b-tab>

          <b-tab :title="$t('DEVICE.PARAMETER_MAPPING')">
            <div class="alert alert-light alert-elevate" role="alert"
              v-if="paramDeviceTypes != null && paramDeviceTypes.length > 0">
              <div class="alert-text">
                <p>{{ $t("DEVICE.PARAMETER_ADD_INFO") }}</p>
                <ul>
                  <li v-for="e in paramDeviceTypes" :key="e.id">
                    {{ e.parameterName }}
                  </li>
                </ul>
              </div>
            </div>

            <b-table id="table-parameters" striped bordered :items="dto.parameterMappings"
              :fields="parameterMappingFields" selectable select-mode="single" @row-selected="onParameterRowSelected">
            </b-table>
          </b-tab>
          <!-- <b-tab :title="$t('DEVICE.DEVICE_FIELDS')" :disabled="!hasFields">
            <device-fields-new
              v-if="hasFields"
              :fieldsData="fieldsData"
              :dto="dto"
              :readOnly="readOnly"
              :deviceFieldsMap="deviceFieldsMap"
            ></device-fields-new>
          </b-tab> -->
        </b-tabs>
        <AddScatDeviceForm ref="addScatDeviceRef" v-if="dto.typeId === '297e9ce2-9134-4004-b67f-21c1683aaede'"
          :powerlines="powerlines" @submit="onSaveClicked" :sensorData="{ ...dto.sensor, name: dto.name }" />
        <div style="float: right">
          <b-button v-if="
            dto.typeId === '297e9ce2-9134-4004-b67f-21c1683aaede' && !readOnly
          " variant="success" v-on:click="submitForm">{{ $t("COMMON.SAVE") }}</b-button>
        </div>
        <div style="float: right">
          <b-button v-if="
            dto.typeId !== '297e9ce2-9134-4004-b67f-21c1683aaede' && !readOnly
          " variant="success" v-on:click="onSaveClicked">{{ $t("COMMON.SAVE") }}</b-button>
        </div>
      </b-card>
    </b-overlay>

    <b-modal static id="modal-create-edit-parameter" :title="addItem
        ? $t('DEVICE.ADD_PARAMETER_MAPPING')
        : $t('DEVICE.EDIT_PARAMETER_MAPPING')
      " size="lg" ok-only @ok="parameterModalOkPressed" :ok-title="$t('COMMON.SAVE')">
      <b-form-group id="field-code" label-cols-lg="2" :label="$t('COMMON.CODE')" label-for="field-code-input">
        <b-form-input id="field-code-input" v-model="parameterMapping.code" trim :state="validateState('code')"
          aria-describedby="input-1-live-feedback">
        </b-form-input>
        <b-form-invalid-feedback id="input-1-live-feedback" :state="validateState('code')">
          {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="field-translate-expression" label-cols-lg="2" :label="$t('DEVICE.TRANSLATE_EXPRESSION')"
        label-for="field-translate-expression-input">
        <b-form-textarea id="field-translate-expression-input" v-model="parameterMapping.translateExpression" trim
          aria-describedby="input-2-live-feedback">
        </b-form-textarea>
      </b-form-group>

      <b-form-group :label="$t('DEVICE.PARAMETER')" label-for="filter1" label-cols-lg="2">
        <CompactSuggestion ref="parameterSuggestionRef" @hit="(val) => (parameterMapping.parameterName = val.name)"
          v-model="parameterMapping.parameterId" suggestionProcessor="parameterSuggestionProcessor"></CompactSuggestion>

        <b-form-invalid-feedback id="parameter-id-live-feedback" :state="validateState('parameterId')">
          {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="field-min-value" label-cols-lg="2" :label="$t('COMMON.MIN_VALUE')"
        label-for="field-min-value-input">
        <b-form-input id="field-min-value-input" v-model="parameterMapping.minValue" trim></b-form-input>
      </b-form-group>

      <b-form-group id="field-max-value" label-cols-lg="2" :label="$t('COMMON.MAX_VALUE')"
        label-for="field-max-value-input">
        <b-form-input id="field-max-value-input" v-model="parameterMapping.maxValue" trim></b-form-input>
      </b-form-group>
    </b-modal>

    <b-modal id="modal-delete-parameter-confirmation" :title="$t('COMMON.CONFIRMATION')" @ok="deleteParameterOkPressed">
      <p class="my-4" v-if="selectedParameter">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedParameter.code,
          })
        }}
      </p>
    </b-modal>

    <b-modal size="lg" class="modal--widget" ref="my-modal">
      <GmapMap :center="{ lat: 45, lng: 38 }" :zoom="4" map-type-id="terrain" style="width: 100%; height: 500px"
        ref="mapRef" :options="{
          streetViewControl: false,
          mapTypeControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: false,
        }" @click="onSelectPoint">
        <GmapMarker :key="index" v-for="(e, index) in coords" :position="e" :clickable="true" :draggable="true" />
      </GmapMap>

      <template v-slot:modal-footer>
        <div class="w-100">
          <p v-if="coords.length > 0" class="float-left">
            {{ $t("COMMON.LATITUDE") + ": " + coords[0].lat }} <br />
            {{ $t("COMMON.LONGITUDE") + ": " + coords[0].lng }}
          </p>

          <b-button variant="primary" size="sm" class="float-right" @click="applyData">
            {{ $t("COMMON.APPLY") }}
          </b-button>

          <b-button size="sm" class="float-right mr-2" @click="closeModal">
            {{ $t("COMMON.CLOSE") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { LocalStore } from "@/common/LocalStore";

import CompactSuggestion from "../component/CompactSuggestion";
import DeviceFields from "../component/device/DeviceFields.vue";

import { sknTypeId } from "@/common/config";
import DeviceFieldsNew from "../component/device/DeviceFieldsNew";
import AddScatDeviceForm from "../content/new_dashboard/components/AddScatDeviceForm.vue";
import axios from "axios";

const ls = new LocalStore("component-map");
const radiusDefaultCircle = 100000;

export default {
  mixins: [validationMixin],
  name: "device",
  components: {
    // DeviceFieldsNew,
    AddScatDeviceForm,
    CompactSuggestion,
  },
  data() {
    return {
      save: {
        resource: "/api/device/save",
        requestType: "POST",
        requestParams: {},
      },
      getTrustLevels: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.TrustLevel",
        requestType: "GET",
        requestParams: {},
      },
      getIntegrations: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.Integration",
        requestType: "GET",
        requestParams: {},
      },
      getDeviceTypesRequest: {
        resource: "/api/device-type",
        requestType: "GET",
        requestParams: {},
      },

      TAB_IDENTIFIERS: 1,

      dto: {
        id: null,
        typeName: null,
        typeId: null,
        locationManual: false,
        imei: null,
        lat: null,
        lng: null,
        password: null,
        active: true,
        publicAvailable: false,
        altitude: null,
        timezoneId: null,
        timezoneOffset: null,
        timezoneLastUpdateDt: null,
        locationLastUpdateDt: null,
        lastDataReceivedDt: null,
        notConnectedNotificationDt: null,
        teamName: null,
        phoneNumber: null,
        teamId: ApiService.teamData.value,
        sensor: null,
        parameterMappings: [],
        parameterMappingsToDelete: [],
        commands: [],
        commandsToDelete: [],
        fields: [],
        forecast: false,
      },

      deviceTypeDescription: null,

      parameterMappingFields: [
        { key: "parameterName", label: this.$t("DEVICE.PARAMETER") },
        { key: "code", label: this.$t("COMMON.CODE") },
        {
          key: "translateExpression",
          label: this.$t("DEVICE.TRANSLATE_EXPRESSION"),
        },
        { key: "minValue", label: this.$t("COMMON.MIN_VALUE") },
        { key: "maxValue", label: this.$t("COMMON.MAX_VALUE") },
      ],

      commandFields: [
        { key: "name", label: this.$t("COMMON.NAME") },
        { key: "description", label: this.$t("COMMON.DESCRIPTION") },
        { key: "value", label: this.$t("DEVICE.VALUE") },
      ],

      commandPropertyFields: [
        { key: "#", formatter: () => "" },
        { key: "code" },
        { key: "name" },
        { key: "description" },
        { key: "defaultValue" },
      ],

      timeZone: null,

      radiusDefaultCircle,

      circleData: this.value,

      coords: [],

      parameterMapping: this.makeEmptyParameter(), // текущий редактируемый параметр
      command: this.makeEmptyCommand(), // текущая редактируемая команда

      addItem: false, // определяет, добавлять или изменять текущий parameterMapping или command

      trustLevels: [],
      integrations: [],
      parameters: [],

      selectedParameter: null, // параметр, выбранный в таблице
      selectedCommand: null, // команда, выбранная в таблице
      selectedCommandProperty: null,

      showOverlay: true,
      confirmPassword: null,

      createNewStr: this.$t("DEVICE.CREATE_NEW_DEVICE"),
      editStr: this.$t("DEVICE.EDIT_DEVICE"),

      paramDeviceTypes: [],
      teamId: ApiService.teamData.value,
      deviceTypes: [],
      deviceTypesMap: {},
      deviceTypeParamMap: [],
      deviceTypeFields: [],
      deviceFieldsMap: {},
      deviceFieldsErrors: new Set(),
      hasFields: false,
      description: null,
      fieldsData: {},
      powerlines: [],
    };
  },

  validations: {
    command: {
      name: { required },
    },
    dto: {
      name: { required },
      typeId: { required },
    },

    parameterMapping: {
      code: {
        required,
      },
      parameterId: {
        required,
      },
    },
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.DEVICES"), route: "/devices" },
      { title: title },
    ]);
    this.$store.dispatch(SET_ACTIONS, null);
    this.loadpowerlines();
  },

  created: async function () {
    if (this.$route.params.id != null) {
      await this.load();
    }

    await this.loadDeviceTypes();

    this.showOverlay = false;
  },

  methods: {
    async loadpowerlines() {
      this.isLoading = true;
      try {
        const templateResponse = await axios.get(`api/oscillogram/powerline`, {
          params: {
            teamId: this.dto.teamId,
          },
        });
        this.powerlines = Object.values(templateResponse.data).map(
          (powerline) => ({
            ...powerline,
            available_widgets: [
              {
                id: "e2f34791-0008-426d-43-5d51986df3e1",
                name: "Показания датчиков",
                parameters: {},
                interval: "MONTH",
                type: "ScatIndications",
                isCustom: false,
              },
              {
                id: "e2f34791-0008-426d-ab43-5d51986e1",
                name: "Синхронные осциллограммы",
                parameters: {},
                interval: "MONTH",
                type: "ASO",
                isCustom: false,
              },
            ],
          })
        );
      } catch (error) {
        this.onError(error);
      } finally {
        this.isLoading = false;
      }
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.parameterMapping[name];
      return $dirty ? !$error : null;
    },

    validateDtoState(name) {
      const { $dirty, $error } = this.$v.dto[name];
      return $dirty ? !$error : null;
    },

    validateCommandState(name) {
      const { $dirty, $error } = this.$v.command[name];
      return $dirty ? !$error : null;
    },

    load: function () {
      const idToLoad = this.dto.id || this.$route.params.id;
      return this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/device/" + idToLoad + "/details",
          requestType: "GET",
          requestParams: { enrich: true },
        })
        .then((response) => {
          this.deviceFieldsMap = {};
          for (let i = 0; i < response.parameterMappings.length; i++) {
            if (response.parameterMappings[i].deviceTypeId != null) {
              this.paramDeviceTypes.push(response.parameterMappings[i]);
              response.parameterMappings.splice(i, 1);
              i--;
            }
          }
          for (const df of response.fields) {
            this.deviceFieldsMap[df.deviceTypeFieldId] = df.value;
          }

          this.description = response.deviceTypeDescription;
          this.dto = response;
        })
        .catch(this.onError);
    },

    onDeviceTypeSelected: function (value) {
      this.paramDeviceTypes = [];
      this.dto.locationManual =
        this.deviceTypesMap[this.dto.typeId].locationManual;
      this.paramDeviceTypes = this.deviceTypeParamMap[this.dto.typeId];
      this.description = this.deviceTypesMap[this.dto.typeId].description;
    },

    onTeamSelected: function (value) {
      this.dto.teamId = value.id;
    },
    submitForm: function () {
      console.log("Submit from parent");
      this.$refs.addScatDeviceRef.submitForm();
    },
    onSaveClicked: function (form) {
      // let err = new Set();
      // for (const dtf of this.deviceTypeFields) {
      //   if (!this.validField(dtf, this.deviceFieldsMap[dtf.id]))
      //     err.add(dtf.id);
      // }
      // this.deviceFieldsErrors = err;
      // if (err.size > 0) {
      //   this.$bvToast.toast(this.$t("DEVICE.FIELD_ERROR"), {
      //     title: this.$t("COMMON.ERROR"),
      //     variant: "danger",
      //     autoHideDelay: 5000,
      //   });
      //   return;
      // }

      // let newFields = [];
      // for (const k of Object.keys(this.deviceFieldsMap)) {
      //   const oldField = this.dto.fields.find(
      //     (el) => el.deviceTypeFieldId === k
      //   );
      //   const entry = {
      //     deviceTypeFieldId: k,
      //     value: this.deviceFieldsMap[k],
      //   };
      //   if (oldField !== undefined) entry.id = oldField.id;
      //   newFields.push(entry);
      // }
      // this.dto.fields = newFields;

      this.errors = [];
      const request = this.save;
      this.prepareTimeZone();
      request.dto = this.dto;

      if (request.dto.typeId === "297e9ce2-9134-4004-b67f-21c1683aaede") {
        console.log("Scat save");
        request.dto.name = form.name;
        request.dto.sensor = form;
      }

      this.showOverlay = true;
      this.$store
        .dispatch(API_REQUEST, request)
        .then(async (response) => {
          this.dto.id = response;
          await this.load();
          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    prepareTimeZone: function () {
      let timeZoneInv = new Date().getTimezoneOffset() / -60;
      timeZoneInv < 0 ? (this.timeZone = "-") : (this.timeZone = "+");
      Math.abs(timeZoneInv) < 10
        ? (this.timeZone = this.timeZone + "0" + Math.abs(timeZoneInv) + ":00")
        : (this.timeZone = this.timeZone + Math.abs(timeZoneInv) + ":00");
    },

    tabChanged: async function (newTabIndex) {
      let that = this;
      switch (newTabIndex) {
        case 1:
          {
            // parameter mapping
            this.$store.dispatch(SET_ACTIONS, [
              {
                label: this.$t("ACTIONS.CREATE_NEW"),
                action: this.onCreateNewParameterAction,
                icon: "fas fa-plus",
                disabled: function () {
                  return that.showOverlay || that.readOnly;
                },
              },
              {
                label: this.$t("ACTIONS.EDIT"),
                action: this.onEditParameterAction,
                disabled: function () {
                  return (
                    that.selectedParameter === null ||
                    that.showOverlay ||
                    that.readOnly
                  );
                },
                icon: "fas fa-edit",
              },
              {
                label: this.$t("ACTIONS.DELETE"),
                action: this.onDeleteParameterAction,
                disabled: function () {
                  return (
                    that.selectedParameter === null ||
                    that.showOverlay ||
                    that.readOnly
                  );
                },
                icon: "fas fa-trash-alt",
              },
            ]);
          }
          break;
        default:
          this.$store.dispatch(SET_ACTIONS, []);
          break;
      }
    },

    onCommandRowSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedCommand = items[0];
      } else {
        this.selectedCommand = null;
      }
    },

    onParameterRowSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedParameter = items[0];
      } else {
        this.selectedParameter = null;
      }
    },

    //
    //
    // PARAMETERS ->

    suggestedParameterSelected: function (item) {
      this.parameterMapping.parameterId = item.id;
    },

    onCreateNewParameterAction: function () {
      this.addItem = true;
      this.parameterMapping = this.makeEmptyParameter();
      this.$refs.parameterSuggestionRef.clearValue();
      this.$bvModal.show("modal-create-edit-parameter");
    },

    onEditParameterAction: function () {
      this.addItem = false;
      this.parameterMapping = this.copyParameter(this.selectedParameter);
      this.$refs.parameterSuggestionRef.setText(
        this.parameterMapping.parameterName
      );
      this.$bvModal.show("modal-create-edit-parameter");
    },

    parameterModalOkPressed: function (bvModalEvt) {
      this.$v.parameterMapping.$touch();
      if (this.$v.parameterMapping.$anyError) {
        bvModalEvt.preventDefault();
        return;
      }

      if (this.addItem) {
        if (this.dto.parameterMappings === null)
          this.dto.parameterMappings = [];
        this.dto.parameterMappings.push(this.parameterMapping);
      } else {
        // update changes in actual object
        for (const field in this.parameterMapping) {
          this.selectedParameter[field] = this.parameterMapping[field];
        }
      }
    },

    onDeleteParameterAction: function () {
      this.$bvModal.show("modal-delete-parameter-confirmation");
    },

    deleteParameterOkPressed: function () {
      for (let i = 0; i < this.dto.parameterMappings.length; i++) {
        const mapping = this.dto.parameterMappings[i];
        if (mapping === this.selectedParameter) {
          this.dto.parameterMappings.splice(i, 1);
          if (!this.dto.parameterMappingsToDelete)
            this.dto.parameterMappingsToDelete = [];
          if (mapping.id && mapping.id != null)
            this.dto.parameterMappingsToDelete.push(mapping.id);
          return;
        }
      }
    },
    // <-

    //
    //
    // COMMANDS ->
    onCreateNewCommandAction: function () {
      this.addItem = true;
      this.command = this.makeEmptyCommand();
      this.$bvModal.show("modal-create-edit-command");
    },

    onEditCommandAction: function () {
      this.addItem = false;
      this.command = this.copyCommand(this.selectedCommand);
      this.$bvModal.show("modal-create-edit-command");
    },

    commandModalOkPressed: function (bvModalEvt) {
      this.$v.command.$touch();
      if (this.$v.command.$anyError) {
        bvModalEvt.preventDefault();
        return;
      }

      this.selectedCommandProperty = null;

      if (this.addItem) {
        if (this.dto.commands === null) this.dto.commands = [];
        this.dto.commands.push(this.command);
      } else {
        // update changes in actual object
        for (const field in this.command) {
          this.selectedCommand[field] = this.command[field];
        }
      }
    },

    onDeleteCommandAction: function () {
      this.$bvModal.show("modal-delete-command-confirmation");
    },

    deleteCommandOkPressed: function () {
      for (let i = 0; i < this.dto.commands.length; i++) {
        const mapping = this.dto.commands[i];
        if (mapping === this.selectedCommand) {
          this.dto.commands.splice(i, 1);
          if (!this.dto.commandsToDelete) this.dto.commandsToDelete = [];
          if (mapping.id && mapping.id != null)
            this.dto.commandsToDelete.push(mapping.id);
          return;
        }
      }
    },

    // <-

    //
    //
    // COMMAND PROPERTY ->

    onCommandPropertyRowSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedCommandProperty = items[0];
      } else {
        this.selectedCommandProperty = null;
      }
    },

    commandPropertyAddClicked: function () {
      if (this.command.commandProperties === null)
        this.command.commandProperties = [];

      this.command.commandProperties.push(this.makeEmptyCommandProperty());
    },

    commandPropertyDeleteClicked: function () {
      for (let i = 0; i < this.command.commandProperties.length; i++) {
        if (
          this.command.commandProperties[i] === this.selectedCommandProperty
        ) {
          if (this.selectedCommandProperty.id !== null) {
            this.command.commandPropertiesToDelete.push(
              this.selectedCommandProperty.id
            );
          }

          this.command.commandProperties.splice(i, 1);
          this.selectedCommandProperty = null;
          return;
        }
      }
    },

    // <-

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    makeEmptyParameter: function () {
      return {
        deviceId: null,
        parameterId: null,
        parameterName: null,
        code: null,
        translateExpression: null,
        minValue: null,
        maxValue: null,
      };
    },

    makeEmptyCommand: function () {
      return {
        name: null,
        description: null,
        value: null,
        deviceTypeId: null,
        commandProperties: [],
        commandPropertiesToDelete: [],
      };
    },

    makeEmptyCommandProperty: function () {
      return {
        commandId: null,
        code: null,
        name: null,
        description: null,
        defaultValue: null,
      };
    },

    copyCommand: function (command) {
      let obj = {};

      for (const field in command) {
        obj[field] = command[field];
      }

      if (command.commandProperties !== null) {
        obj.commandProperties = [];
        for (let i = 0; i < command.commandProperties.length; i++)
          obj.commandProperties.push(
            this.copyCommandProperty(command.commandProperties[i])
          );
      } else {
        obj.commandProperties = [];
      }

      if (command.commandPropertiesToDelete != null) {
        obj.commandPropertiesToDelete =
          command.commandPropertiesToDelete.slice();
      } else {
        obj.commandPropertiesToDelete = [];
      }

      return obj;
    },

    copyCommandProperty: function (commandProperty) {
      let obj = {};

      for (const field in commandProperty) {
        obj[field] = commandProperty[field];
      }

      return obj;
    },

    copyParameter: function (parameter) {
      let obj = {};

      for (const field in parameter) {
        obj[field] = parameter[field];
      }

      return obj;
    },

    onShowModal() {
      this.coords.push({ lat: this.dto.lat, lng: this.dto.lng });
      return this.$refs["my-modal"].show();
    },

    setDeviceCoords(e) {
      if (this.coords.length != 0) {
        this.coords.splice(0, this.coords.length - 1);
      }
    },

    closeModal() {
      this.coords = [];
      return this.$refs["my-modal"].hide();
    },

    onSelectPoint(e) {
      if (this.coords.length > 0) this.coords = [];

      this.coords.push({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    },

    applyData() {
      if (this.coords.length > 0) {
        this.dto.lat = this.coords[0].lat;
        this.dto.lng = this.coords[0].lng;
        this.coords = [];
      }
      this.closeModal();
    },

    loadDeviceTypes: function () {
      return this.$store
        .dispatch(API_REQUEST, this.getDeviceTypesRequest)
        .then((response) => {
          let resp = response.content;
          for (let i = 0; i < resp.length; i++) {
            this.deviceTypes.push({ value: resp[i].id, text: resp[i].name });
            if (
              resp[i].id === this.dto.typeId &&
              resp[i].deviceTypeFields !== null
            ) {
              this.hasFields = true;
              this.deviceTypeFields = resp[i].fields;
              for (const dtf of resp[i].fields) {
                this.fieldsData[dtf.id] = dtf;
              }
            }
            this.deviceTypesMap[resp[i].id] = resp[i];
            if (
              resp[i].parameterMappings != null &&
              resp[i].parameterMappings.length > 0
            )
              this.deviceTypeParamMap[resp[i].id] = resp[i].parameterMappings;
          }
        })
        .catch(this.onError);
    },

    validField(dtf, value) {
      if (!dtf.regex) return true;
      const re = new RegExp(dtf.regex);
      return re.test(value);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    readOnly() {
      return (
        !this.user.grants.includes("SYSTEM:ADMIN") &&
        this.dto.id != null &&
        this.dto.teamId != this.teamId
      );
    },
    isSKN() {
      return this.dto.typeId === sknTypeId;
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}

.form-group {
  margin-bottom: 1rem;
}

.additional_devices {
  padding: 1.15rem;
  display: flex;
  flex-wrap: wrap;
}

.additional_devices_button {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  width: 217px;
  height: 217px;
  border-radius: 1rem;
  background: #ffffff;
  margin-right: 1rem;
  padding: 0.75rem;
  justify-content: space-between;
  border: 2px solid #44486026;
  transition: 0.3s;
  margin-bottom: 1rem;
}

.additional_devices_button:hover {
  border-color: #64cff2;
}

.additional_devices_button_img {
  object-fit: contain;
  width: 70%;
  height: 70%;
  flex: 1;
  padding: auto;
}

.card-selected {
  opacity: 1;
  border-color: #64cff2;
}

.card-deselected {
  opacity: 0.5;
}
</style>
