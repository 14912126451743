<template>
  <div class="widget-container">
    <button
      @click="selectWidget"
      :class="[
        'list-group-item',
        'list-group-item-action',
        { 'add-widget-button': widget.type === 'add_widget_button' },
      ]"
    >
      <span class="widget-icon">
        <img :src="icon" class="mr-2" />
      </span>
      <span class="widget-text">{{widget.name}}</span>
    </button>
    <ConfirmDeleteModal v-if="widget.isCustom" :item-id="widget.id" :on-delete="removeTemplate">
      <template #button>
        <button class="remove-btn">
          <img src="media/svg/buttons/delete.svg" alt="" />
        </button>
      </template>
    </ConfirmDeleteModal>
  </div>
</template>

<script>
import ConfirmDeleteModal from "../components/ConfirmDeleteModal.vue";
export default {
  components: {
    ConfirmDeleteModal,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    deviceId: {
      type: [String, Number],
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  methods: {
    selectWidget() {
      this.$emit("widget-selected", {
        deviceId: this.deviceId,
        widget: this.widget,
      });
    },
    removeTemplate(id) {
      this.$emit("deleteTemplate", id);
    },
  },
};
</script>

<style scoped>
.widget-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  column-gap: 1rem;
}

.list-group-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 7px;
  color: #181d31;
  padding: 5px 0px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  letter-spacing: -0.01em;
  text-align: left;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s, box-shadow 0.3s;
  flex-grow: 1;
}
.list-group-item:hover {
  background-color: rgba(223, 226, 236, 1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

}

.widget-icon {
  padding: 5px 7px;
  border-radius: 3px;
  color: rgba(87, 237, 228, 1);
  flex-shrink: 0;
}

.widget-text {
  padding-left: 5px;
  word-wrap: break-word;
  white-space: normal;
}

.add-widget-button {
  background-color: rgba(169, 238, 233, 0.2);
  color: #2b2d42;
}

.remove-widget-button {
  background: none;
  border: none;
  color: #ff6b6b;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
}
.remove-widget-button:hover {
  color: #ff1c1c;
}
</style>


