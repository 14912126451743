<template>
  <div class="dashboard-skat">
    <div class="widget-tabs">
      <div v-for="widget in layouts.desktop" :key="widget.i" class="widget-tab"
        :class="{ 'active': activeWidgetId === widget.i }" @click="setActiveWidget(widget.i)">
        <span class="tab-title">
          {{ widget.deviceName }}
          <br>
          <p class="font-italic">{{ widget.name }}</p>
        </span>
        <button class="tab-close" @click.stop="removeWidget(widget.i)">
          ×
        </button>
      </div>
    </div>

    <div class="widget-content-skat" v-if="activeWidgetId">
      <ChartWrapperSkat v-if="activeWidget" @update-widget="updateWidget" @remove-widget="removeWidget"
        :widget="activeWidget" :isForecastWidget="isForecastWidget(activeWidget)" :path="'skat'" :layouts="layouts" />
    </div>
  </div>
</template>

<script>
import ChartWrapperSkat from "./charts/ChartWrapperSkat";

export default {
  components: {
    ChartWrapperSkat
  },

  props: {
    devices: {
      type: Array,
      required: true
    },
    layouts: {
      type: Object,
      required: true,
      default: () => ({
        desktop: [],
        tablet: [],
        mobile: []
      })
    }
  },

  data() {
    return {
      activeWidgetId: null
    }
  },

  computed: {
    activeWidget() {
      return this.layouts.desktop.find(widget => widget.i === this.activeWidgetId)
    }
  },

  watch: {
    'layouts.desktop': {
      handler(newWidgets) {
        if (newWidgets.length && !this.activeWidgetId) {
          this.activeWidgetId = newWidgets[0].i;
        } else if (!newWidgets.length) {
          this.activeWidgetId = null;
        } else if (this.activeWidgetId && !newWidgets.find(w => w.i === this.activeWidgetId)) {
          // Если активный виджет был удален, переключаемся на первый доступный
          this.activeWidgetId = newWidgets[0].i;
        }
      },
      immediate: true
    }
  },

  methods: {
    setActiveWidget(widgetId) {
      this.activeWidgetId = widgetId;
    },

    updateWidget(widget) {
      const updatedWidget = {
        ...widget,
        x: this.getWidgetPosition(widget.i, 'x'),
        y: this.getWidgetPosition(widget.i, 'y'),
        w: this.getWidgetPosition(widget.i, 'w'),
        h: this.getWidgetPosition(widget.i, 'h')
      };

      this.$emit('update-widget', updatedWidget);
    },

    getWidgetPosition(widgetId, prop) {
      const layouts = ['desktop', 'tablet', 'mobile'];
      for (const layout of layouts) {
        const widget = this.layouts[layout].find(w => w.i === widgetId);
        if (widget) {
          return widget[prop];
        }
      }
      return null;
    },

    removeWidget(widgetId) {
      this.$emit('remove-widget', widgetId);

      // Если удаляем активный виджет, переключаемся на другой
      if (this.activeWidgetId === widgetId) {
        const remainingWidgets = this.layouts.desktop.filter(w => w.i !== widgetId);
        this.activeWidgetId = remainingWidgets.length ? remainingWidgets[0].i : null;
      }
    },

    isForecastWidget(widget) {
      if (!widget) return false;
      let device = this.devices.find((device) => device.id === widget.deviceId);
      return device ? device.forecast : false;
    }
  }
}
</script>

<style scoped>
.dashboard-skat {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.widget-tabs {
  display: flex;
  overflow-x: auto;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.widget-tab {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: white;
  border: 1px solid #ddd;
  border-bottom: none;
  margin-right: 2px;
  cursor: pointer;
  user-select: none;
  min-width: 150px;
  max-width: 200px;
}

.widget-tab.active {
  background: #fff;
  border-bottom: 2px solid #64cff2;
}

.tab-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-close {
  margin-left: 8px;
  border: none;
  background: none;
  font-size: 18px;
  cursor: pointer;
  padding: 0 4px;
}

.tab-close:hover {
  color: red;
}

.widget-content-skat {
  flex: 1;
  padding: 16px;
  overflow: auto;
  background-color: #fff;
  min-height: 80vh;
}

@media (max-width: 768px) {
  .widget-tab {
    min-width: 120px;
    padding: 6px 12px;
  }
}

@media (max-width: 480px) {
  .widget-tab {
    min-width: 100px;
    padding: 4px 8px;
  }

  .widget-content-skat {
    padding: 8px;
  }
}
</style>
