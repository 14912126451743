<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        {{ $t("QUICK_USER.HI") }},
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ user.formattedUserName }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="pictureExist" alt="Pic" :src="pictureUrl" />
        <span v-else class="symbol-label font-size-h5 font-weight-bold">
          {{ getUsernameFirstLetter() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          {{ $t("QUICK_USER.USER_PROFILE") }}
          <!--<small class="text-muted font-size-sm ml-2">12 messages</small>-->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="pictureUrl" alt="" />
          </div>
          <div class="d-flex flex-column">
            <a
              :href="'/profile'"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              @click.prevent="goToProfile"
            >
              {{ user.formattedUserName }}
            </a>
            <div
              class="text-hover-primary mt-1"
              style="margin-top: 0px !important"
            >
              <a
                :href="'/team/' + defaultTeam.id"
                @click.prevent="goToMyTeam"
                >{{ formatStatusLine }}</a
              >
            </div>
            <!--<div class="navi mt-2">
            <a href="#" class="navi-item">
            <span class="navi-link p-0 pb-2">
            <span class="navi-icon mr-1">
            <span class="svg-icon svg-icon-lg svg-icon-primary">
            &lt;!&ndash;begin::Svg Icon&ndash;&gt;
            <inline-svg
            src="media/svg/icons/Home/Mail-notification.svg"
            />
            &lt;!&ndash;end::Svg Icon&ndash;&gt;
            </span>
            </span>
            <span class="navi-text text-muted text-hover-primary">
            jm@softplus.com
            </span>
            </span>
            </a>
            </div>-->
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              {{ $t("QUICK_USER.SIGN_OUT") }}
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>

        <!-- TEAMS -->
        <div class="navi navi-spacer-x-0 p-0">
          <a
            href="javascript:"
            class="navi-item"
            v-for="(team, index) in myTeams"
            :key="team.id"
            v-on:click="selectTeam(team.id, team.id == defaultTeam.id, index)"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg src="/media/svg/icons/Home/Home.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">{{ team.name }}</div>
                <div class="text-muted">
                  {{ team.tariffName }}
                </div>
                <div class="text-muted" v-if="team.defermentExpiration != null">
                  {{ formatExpirationLine(team.defermentExpiration) }}
                </div>
              </div>
            </div>
          </a>

          <a href="javascript:" class="navi-item" v-on:click="addTeam()">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="/media/svg/icons/Communication/Add-user.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  {{ $t("QUICK_USER.ADD_TEAM") }}
                </div>
                <div class="text-muted"></div>
              </div>
            </div>
          </a>
        </div>
        <!-- TEAMS -->
        <div class="separator separator-dashed my-7"></div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { mapState, mapActions, mapMutations } from "vuex";
import Application from "../../../../application";
import ApiService from "@/core/services/api.service";
import { SET_LOGO } from "../../../../core/services/store/auth.module";
import { SET_DEFAULT_TEAM } from "../../../../core/services/store/team.module";

export default {
  name: "KTQuickUser",
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },

  created: async function () {
    if (localStorage.getItem("invitationId") != null) {
      let invitationId = localStorage.getItem("invitationId");
      localStorage.removeItem("invitationId");

      ApiService.post("/api/team/accept-invitation/" + invitationId)
        .then((data) => {
          window.location.reload();
        })
        .catch(() => {
          //console.log("Failed to accept invitation");
        });
    }
  },

  methods: {
    ...mapMutations(["setMyTeams"]),
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => {
        window.location = "/";
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    getUsernameFirstLetter: function () {
      if (
        this.user.formattedUserName == null ||
        this.user.formattedUserName == ""
      ) {
        return "A";
      }
      return this.user.formattedUserName[0].toUpperCase();
    },
    goToPersonalNotifications: function () {
      this.$router.push({ name: "personal-notifications" });
    },

    goToMyTeam() {
      this.$router.push({ name: "team", params: { id: this.defaultTeam.id } });
      this.closeOffcanvas();
    },

    selectTeam: function (id, defaultTeam, index) {
      console.log("defaultTeam");
      console.log(defaultTeam);
      if (id == null) return;

      if (defaultTeam) {
        this.$router.push({ name: "team", params: { id: id } });
        this.closeOffcanvas();
      } else {
        // Set logo
        if (this.myTeams[index].logo) {
          localStorage.setItem("teamLogo", this.myTeams[index].logo);
          this.$store.commit(SET_LOGO, this.myTeams[index].logo);
        } else {
          localStorage.removeItem("teamLogo");
          this.$store.commit(SET_LOGO, Application.constants.DEFAULT_LOGO);
        }

        console.log("myteams[index]");
        console.log(this.myTeams[index]);
        this.$store.commit(SET_DEFAULT_TEAM, this.myTeams[index]);
        
        window.location.reload();
      }
    },
    addTeam: function () {
      this.$router.push({ name: "team" });
      this.closeOffcanvas();
    },
    goToProfile: function () {
      this.$router.push({ name: "profile" });
      this.closeOffcanvas();
    },
    formatExpirationLine(date) {
      if (date != null) {
        let d = new Date(date);
        return this.$t("COMMON.TRIAL") + " " + d.toLocaleDateString();
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      defaultTeam: (state) => state.team.defaultTeam,
      myTeams: (state) => state.team.myTeams,
    }),

    pictureUrl() {
      return this.$store.getters.currentUser.fields.avatar != null
        ? this.$store.getters.currentUser.fields.avatar
        : process.env.BASE_URL + "media/users/default.jpg";
    },

    pictureExist() {
      return this.$store.getters.currentUser.fields.avatar != null;
    },

    formatStatusLine() {
      let defaultTeam = this.defaultTeam;
      if (defaultTeam == null) {
        return "Natlogger user";
      } else {
        return defaultTeam.name;
      }
    },
  },
};
</script>
