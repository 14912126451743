<template>
  <div>
    <h5 v-if="data && data.segment && firstFailure" class="title">
      №{{ data.id }} от {{ formatDate(data.dateUTC) }}
    </h5>
    <p v-if="data && data.segment && firstFailure" class="description">
      Событие между вершинами {{ data.segment.vertexAid }} и
      {{ data.segment.vertexBid }} на расстоянии {{ closestDistance }} м от
      вершины {{ closestVertex }}. Для
      расчета расстояния выбраны датчик {{ data.sensorAName }} (
      {{ vertexAMeasure.sensorId }} канал Ch{{
        vertexAMeasure.emergChNumber
      }}) и датчик {{ data.sensorBName }} ({{
        vertexBMeasure.sensorId
      }}
      канал Ch{{ vertexBMeasure.emergChNumber }}).
    </p>
    <div>
      <b-table-simple v-if="data && data.details && data.details.length" hover bordered responsive small
        class="text-center">
        <b-thead head-variant="light">
          <tr>
            <th>Датчик A</th>
            <th>Датчик B</th>
            <th>Расстояние, м</th>
            <th>Время, нс</th>
            <th>Скорость, м/с</th>
          </tr>
        </b-thead>
        <tbody>
          <tr v-for="failure in data.details" :key="failure.id">
            <td>{{ failure.sensorAId }}</td>
            <td>{{ failure.sensorBId }}</td>
            <td>{{ failure.sensorADistance }}</td>
            <td>{{ failure.deltaTime }}</td>
            <td>{{ failure.speed }}</td>
          </tr>
        </tbody>
      </b-table-simple>
    </div>
    <div class="w-100">
      <GraphTooltip :dynamicLabels="seriesLabels" :highlightedPoints="highlightedPoints" :seriesColors="seriesColors"
        :tooltipVisible="tooltipVisible" :visibleSeries="visibleSeries" :scalingFactors="scalingFactors"
        target="mainGraph">
        <template v-slot:title>
          <strong>Index: </strong>
          {{ highlightedIndex }}
        </template>
      </GraphTooltip>
      <div class="w-100 px-0" v-if="data && data.measurements && data.measurements.length" ref="mainGraph"></div>
    </div>
    <b-table-simple v-if="data && data.measurements && data.measurements.length" bordered responsive small hover
      class="text-center py-4">
      <b-thead head-variant="light">
        <tr>
          <th>Устройство</th>
          <th>Ch0</th>
          <th>Ch1</th>
          <th>Ch2</th>
        </tr>
      </b-thead>
      <tbody>
        <tr v-for="(measuring, measuringIndex) in data.measurements" :key="measuring.sensorId">
          <td @click="selectFailure(measuring.id)" style="color: rgb(41 106 204)">
            {{ measuring.sensorId }}
          </td>
          <td>
            <label class="custom-checkbox">
              <input type="checkbox" :checked="visibleSeries[measuringIndex * 3]"
                @change="toggleSeriesVisibility(measuringIndex * 3)" />
              <span class="checkbox" :style="{ '--color': seriesColors[measuringIndex * 3] }"></span>
              <!-- {{ seriesLabels[measuringIndex * 3] }} -->
              <!-- CH0 -->
            </label>
          </td>
          <td>
            <label class="custom-checkbox">
              <input type="checkbox" :checked="visibleSeries[measuringIndex * 3 + 1]"
                @change="toggleSeriesVisibility(measuringIndex * 3 + 1)" />
              <span class="checkbox" :style="{ '--color': seriesColors[measuringIndex * 3 + 1] }"></span>
              <!-- {{ seriesLabels[measuringIndex * 3 + 1] }} -->
              <!-- CH1 -->
            </label>
          </td>
          <td>
            <label class="custom-checkbox">
              <input type="checkbox" :checked="visibleSeries[measuringIndex * 3 + 2]"
                @change="toggleSeriesVisibility(measuringIndex * 3 + 2)" />
              <span class="checkbox" :style="{ '--color': seriesColors[measuringIndex * 3 + 2] }"></span>
              <!-- {{ seriesLabels[measuringIndex * 3 + 2] }} -->
              <!-- CH2 -->
            </label>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
    <b-modal size="xl" title="Датчик" hide-footer class="modal fade" id="detailModal" ref="failureDetailModal"
      tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
      <MeasurementDetail v-if="selectedFailure" :withBackBtn="false" :sensor-id="selectedFailure"
        :widget="{ ...widget, h: 32 }" />
    </b-modal>
  </div>
</template>

<script>
import Dygraph from "dygraphs";
import MeasurementDetail from "./MeasurementDetail.vue";
import GraphTooltip from "./GraphTooltip.vue";

export default {
  components: {
    MeasurementDetail,
    GraphTooltip,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedFailure: null,
      visibleSeries: this.data.measurements.flatMap((measuring) => [
        measuring.emergChNumber === 0 &&
        (measuring.sensorId === this.data.sensorAId ||
          measuring.sensorId === this.data.sensorBId),
        measuring.emergChNumber === 1 &&
        (measuring.sensorId === this.data.sensorAId ||
          measuring.sensorId === this.data.sensorBId),
        measuring.emergChNumber === 2 &&
        (measuring.sensorId === this.data.sensorAId ||
          measuring.sensorId === this.data.sensorBId),
      ]),
      scalingFactors: [],
      highlightedPoints: [],
      highlightedIndex: 0,
      tooltipVisible: false,
      // visibleSeries: this.initializeVisibleSeries(),
      seriesLabels: this.data.measurements.flatMap((measuring) => [
        `OSC CH0 (sensor: ${measuring.sensorId})`,
        `OSC CH1 (sensor: ${measuring.sensorId})`,
        `OSC CH2 (sensor: ${measuring.sensorId})`,
      ]),
      // seriesColors: this.data.measurements.flatMap(() => [
      //   "#1f77b4",
      //   "#ff7f0e",
      //   "#2ca02c",
      // ]),
      seriesColors: [
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffb55a",
        "#ffee65",
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
      ],

      initialRange: null,
    };
  },
  computed: {
    firstFailure() {
      return this.data &&
        this.data.details &&
        this.data.details.length > 0
        ? this.data.details[0]
        : null;
    },
    closestVertex() {
      const sAD = this.data.segmentADistance;
      const sD = this.data.segment.distance;
      if (sAD < (Math.abs(sD - sAD))) return this.data.segment.vertexAid;
      else return this.data.segment.vertexBid;
    },
    closestDistance() {
      const sAD = this.data.segmentADistance;
      const sD = this.data.segment.distance;
      return Math.min(sAD, Math.abs(sD - sAD));
    },
    vertexAMeasure() {
      return this.data.measurements.find(m => m.sensorId == this.data.sensorAId);
    },
    vertexBMeasure() {
      return this.data.measurements.find(m => m.sensorId == this.data.sensorBId);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.data && this.data.measurements && this.$refs.mainGraph) {
        this.renderMainGraph();
      }
    });
  },
  methods: {
    initializeVisibleSeries() {
      return this.data.measurements.flatMap((measuring, measuringIndex) => {
        const usedForCalculation = this.isUsedInCalculation(measuring.sensorId);

        return [
          usedForCalculation && measuring.emergChNumber === 0,
          usedForCalculation && measuring.emergChNumber === 1,
          usedForCalculation && measuring.emergChNumber === 2,
        ];
      });
    },

    isUsedInCalculation(sensorId) {
      const usedSensorIds = [
        this.firstFailure?.sensorAId,
        this.firstFailure?.sensorBId,
      ];
      return usedSensorIds.includes(sensorId);
    },
    selectFailure(failure) {
      this.selectedFailure = failure;
      this.openModal();
    },
    openModal() {
      this.$refs.failureDetailModal.show();
    },
    closeModal() {
      this.$refs.failureDetailModal.hide();
    },
    clearSelectedFailure() {
      this.selectedFailure = null;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString("ru-RU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    mapDataToSeries(measurements, channels) {
      let maxLength = Math.max(
        ...measurements.map((m) =>
          Math.max(...channels.map((channel) => m[channel]?.length || 0))
        )
      );

      let result = Array.from({ length: maxLength }, (_, idx) => [idx]);

      measurements.forEach((measuring, measuringIndex) => {
        channels.forEach((channel, channelIndex) => {
          measuring[channel]?.forEach((value, i) => {
            if (!result[i]) {
              result[i] = Array(channels.length + 1).fill(null);
            }
            result[i][measuringIndex * 3 + channelIndex + 1] = value || 0;
          });
        });
      });

      return result;
    },
    renderMainGraph() {
      const labels = ["Distance (m)"];
      this.data.measurements.forEach((measuring) => {
        labels.push(
          `OSC CH0 (sensor: ${measuring.sensorId})`,
          `OSC CH1 (sensor: ${measuring.sensorId})`,
          `OSC CH2 (sensor: ${measuring.sensorId})`
        );
      });

      const seriesData = this.mapDataToSeries(this.data.data, [
        "osc_ch0_shifted",
        "osc_ch1_shifted",
        "osc_ch2_shifted",
      ]);

      if (labels.length !== seriesData[0].length) {
        console.error(
          `Mismatch between number of labels (${labels.length}) and number of columns in array (${seriesData[0].length})`
        );
        return;
      }

      const maxYValue = Math.max(
        ...seriesData.flatMap((row) =>
          row
            .slice(1)
            .filter(
              (value, index) =>
                this.visibleSeries[index] && typeof value === "number"
            )
        )
      );

      const annotations = this.data.details.map((failure) => ({
        x: failure.sensorADistance,
        text: `Авария между сенсорами ${failure.sensorAId} и ${failure.sensorBId}`,
        shortText: "A",
        cssClass: "graph-annotation",
      }));

      this.mainGraph = new Dygraph(this.$refs.mainGraph, seriesData, {
        labels,
        strokeWidth: 1.5,
        showRangeSelector: true,
        legend: "never",
        visibility: this.visibleSeries,
        colors: this.seriesColors,
        interactionModel: Dygraph.defaultInteractionModel,
        zoomCallback: this.syncZoom,
        panEdgeFraction: 0.1,
        connectSeparatedPoints: true,
        highlightCallback: this.showCustomTooltip,
        unhighlightCallback: this.clearCustomTooltip,
        axes: {
          x: {
            drawGrid: true,
            axisLabelFormatter: function (x) {
              return x;
            },
          },
          y: {
            drawGrid: true,
            // valueRange: [0, maxYValue + 30],
            axisLabelFormatter: function (y) {
              return y.toFixed(0);
            },
            // axisRangePadding: 0.1,
          },
        },
        underlayCallback: (canvas, area, g) => {
          this.data.data.forEach((measuring, measuringIndex) => {
            const nsppValues = [
              measuring.flag_ch0_shifted,
              measuring.flag_ch1_shifted,
              measuring.flag_ch2_shifted,
            ];

            nsppValues.forEach((nspp, channelIndex) => {
              const seriesIndex = measuringIndex * 3 + channelIndex;
              if (this.visibleSeries[seriesIndex]) {
                const x = g.toDomXCoord(nspp);
                canvas.beginPath();
                canvas.moveTo(x, area.y);
                canvas.lineTo(x, area.y + area.h);
                canvas.strokeStyle = "red";
                canvas.lineWidth = 2;
                canvas.stroke();
              }
            });
          });
        },
      });

      this.initialRange = this.mainGraph.xAxisRange();
      this.$refs.mainGraph.addEventListener("wheel", this.handleWheelZoom);
      this.mainGraph.setAnnotations(annotations);
    },
    handleWheelZoom(event) {
      event.preventDefault();
      const normal = event.deltaY ? event.deltaY / Math.abs(event.deltaY) : 0;
      const percentage = normal * -0.1;
      const axis = this.mainGraph.xAxisRange();
      const delta = (axis[1] - axis[0]) * percentage;

      let newStart = axis[0] + delta;
      let newEnd = axis[1] - delta;

      if (newStart < this.initialRange[0]) newStart = this.initialRange[0];
      if (newEnd > this.initialRange[1]) newEnd = this.initialRange[1];

      if (newEnd - newStart > 1) {
        this.mainGraph.updateOptions({
          dateWindow: [newStart, newEnd],
        });
      }
    },
    syncZoom(minDate, maxDate, yRanges) {
      console.log("Zoom range:", minDate, maxDate);
    },
    toggleSeriesVisibility(index) {
      this.$set(this.visibleSeries, index, !this.visibleSeries[index]);
      this.mainGraph.updateOptions({
        visibility: this.visibleSeries,
      });
    },
    showCustomTooltip(event, x, points) {
      this.highlightedPoints = points;
      this.highlightedIndex = x;
      this.tooltipVisible = true;
    },

    clearCustomTooltip() {
      this.tooltipVisible = false;
    },
  },
  beforeDestroy() {
    if (this.$refs.mainGraph) {
      this.$refs.mainGraph.removeEventListener("wheel", this.handleWheelZoom);
    }
  },
};
</script>

<style scoped>
.title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.osc_chart {
  margin-top: 20px;
}

.description {
  font-size: 1em;
  margin-bottom: 15px;
  color: #333;
}

.down-table {
  margin-top: 20px;
}

.legend-toggle {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox .checkbox {
  width: 16px;
  height: 16px;
  border: 2px solid var(--color);
  background-color: transparent;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-checkbox input:checked+.checkbox {
  background-color: var(--color);
}

.custom-checkbox input:not(:checked)+.checkbox {
  background-color: transparent;
}
</style>
