import Vue from "vue";
// mutation types
export const LOAD_TEAMS = "loadTeams";
export const SET_TEAMS = "setTeams";
export const SET_DEFAULT_TEAM = "setDefaultTeam";
import JwtService from "@/core/services/jwt.service";

export default {
  state: {
    myTeams: [],
    defaultTeam: {},
  },
  getters: {
    /**
     * Get list of teams
     * @param state
     * @returns {*}
     */
    myTeams(state) {
      return state.myTeams;
    },
    defaultTeam(state) {
      return state.defaultTeam;
    },
  },
  actions: {
    [LOAD_TEAMS](state) {
      let token = JwtService.getToken();
      if (!token || token == "undefined") return Promise.resolve();

      if (state.getters.myTeams && state.getters.myTeams.length > 0)
        return Promise.resolve();

      return Vue.axios
        .get("/api/profile/teams?enrich=true")
        .then((response) => {
          console.log(response);
          state.commit(SET_TEAMS, response.data);

          const defaultTeamId = localStorage.getItem("defaultTeam");
          let foundDefaultTeam = false;

          if (defaultTeamId) {
            const storedTeam = response.data.find(t => t.id === defaultTeamId);
            if (storedTeam) {
              state.commit(SET_DEFAULT_TEAM, storedTeam);
              foundDefaultTeam = true;
            }
          }

          if (!foundDefaultTeam && response.data.length > 0) {
            state.commit(SET_DEFAULT_TEAM, response.data[0]);
          }
        })
        .catch(() => {});
    },
  },
  mutations: {
    [SET_TEAMS](state, payload) {
      state.myTeams = payload;
    },

    [SET_DEFAULT_TEAM](state, payload) {
      console.log("payload", payload);
      state.defaultTeam = payload;
      localStorage.setItem("defaultTeamName", payload.name);
      if (payload && payload.id) {
        
        localStorage.setItem("defaultTeam", payload.id);
      }
    },
  },
};
