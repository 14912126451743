<template>
  <div>
    <grid-layout
      ref="gridLayout"
      :layout="currentLayout"
      :col-num="gridColumns"
      :row-height="30"
      :is-draggable="deviceType === 'desktop'"
      :is-resizable="deviceType === 'desktop'"
      :vertical-compact="true"
      :use-css-transforms="true"
      :margin="[10, 10]"
    >
      <grid-item
        v-for="widget in currentLayout"
        :key="widget.i"
        :x="widget.x"
        :y="widget.y"
        :w="widget.w"
        :h="widget.h"
        :i="widget.i"
        :min-w="deviceType === 'desktop' ?  4: 1"
        :max-w="deviceType === 'desktop' ? 12 : 1"
        :max-h="30"
        :min-h="20"
        drag-allow-from=".drag-header"
        @resize="resizeEvent"
        @move="moveEvent"
        @resized="resizedEvent"
        @moved="movedEvent"
        class="mx-0 px-0 bg-white rounded overflow-none"
      >
        <ChartWrapper
          :editMode="widget.i === changeWidget"
          @remove-widget="removeWidget"
          :widget="widget"
          :isForecastWidget="isForecastWidget(widget)"
          @update-widget="$emit('update-widget', $event)"
        ></ChartWrapper>
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import ChartWrapper from "./charts/ChartWrapper";

const { GridLayout, GridItem } = VueGridLayout;

export default {
  components: {
    GridLayout,
    GridItem,
    ChartWrapper,
  },
  
  data() {
    return {
      changeWidget: null,
      deviceType: 'desktop', // 'desktop', 'tablet', 'mobile'
    };
  },

  computed: {
    gridColumns() {
      return this.deviceType === 'desktop' ? 12 : 1;
    },

    currentLayout() {
      return this.layouts[this.deviceType] || [];
    }
  },

  mounted() {
    console.log(this.layouts, "layouts");
    console.log(this.currentLayout, "currentLayout");
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },

  props: {
    devices: {
      type: Array,
      required: true,
      default: () => []
    },
    layouts: {
      type: Object,
      required: true,
      default: () => ({
        desktop: [],
        tablet: [],
        mobile: []
      })
    },
  },

  methods: {
    checkScreenSize() {
      const width = window.innerWidth;
      if (width <= 768) {
        this.deviceType = 'mobile';
      } else if (width <= 1280) {
        this.deviceType = 'tablet';
      } else {
        this.deviceType = 'desktop';
      }
    },

    isForecastWidget(widget) {
      if (!this.devices || !widget) return false;
      let device = this.devices.find((device) => device.id === widget.deviceId);
      return device ? device.forecast : false;
    },

    removeWidget(widgetId) {
      this.changeWidget = null;
      this.$emit("remove-widget", widgetId);
    },

    moveEvent(i) {
      this.changeWidget = i;
      document.addEventListener("mouseup", this.handleMoveEnd);
    },

    resizeEvent(i) {
      this.changeWidget = i;
      document.addEventListener("mouseup", this.handleResizeEnd);
    },

    handleResizeEnd() {
      this.changeWidget = null;
      document.removeEventListener("mouseup", this.handleResizeEnd);
    },

    handleMoveEnd() {
      this.changeWidget = null;
      document.removeEventListener("mouseup", this.handleMoveEnd);
    },

    movedEvent(i, newX, newY) {
      if (this.deviceType === 'desktop') {
        const updatedLayout = this.currentLayout.map((widget) => {
          if (widget.i === i) {
            return { ...widget, x: newX, y: newY };
          }
          return widget;
        });
        this.$emit("layout-updated", {
          layout: updatedLayout,
          deviceType: this.deviceType
        });
      }
    },

    resizedEvent(i, newH, newW) {
      if (this.deviceType === 'desktop') {
        const updatedLayout = this.currentLayout.map((widget) => {
          if (widget.i === i) {
            return { ...widget, w: newW, h: newH };
          }
          return widget;
        });
        this.$emit("layout-updated", {
          layout: updatedLayout,
          deviceType: this.deviceType
        });
      }
    },
  },
};
</script>

<style scoped>
/* .grid-item-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.1);
  background-color: #ffffff;
  border: 1px solid #f2f3f8;
  border-radius: 20px;
  margin: 0;
  height: 100%;
  padding: 20px;
} */

/* @media (max-width: 1024px) {
  .grid-item-container {
    padding: 5px;
    border-radius: 10px;
  }
}

@media (max-width: 768px) {
  .grid-item-container {
    padding: 5px;
    border-radius: 10px;
  }
} */
</style>
