import ApiService from "./api.service";

const i18nService = {
  defaultLanguage: "en",

  languages: [
    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg",
    },
    {
      lang: "ru",
      name: "Русский",
      flag: process.env.BASE_URL + "media/svg/flags/248-russia.svg",
    },
    {
      lang: "es",
      name: "Español",
      flag: process.env.BASE_URL + "media/svg/flags/016-spain.svg",
    },
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
    window.location.reload();

    // this.setBackendLanguage(lang, true);
  },

  setBackendLanguage(lang, reloadPage) {
    let request = {
      value: lang,
    };

    ApiService.post("/platform/api/auth/change-locale", request)
      .then(() => {
        if (reloadPage) {
          window.location.reload();
        }
      })
      .catch(() => {
        // console.log("Failed to change locale");
      });
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return localStorage.getItem("language") || this.defaultLanguage;
  },
};

export default i18nService;
