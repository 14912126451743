var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.path === 'skat')?[_c('div',{staticClass:"devices-block"},[_c('p',{staticClass:"devices-title"},[_vm._v(" "+_vm._s(_vm.$t("NEW_DASHBOARD.POWERLINE"))+" "),_c('VisibleToggleButton',{attrs:{"set-visible":_vm.togglePowerlineVisibility,"is-visible":_vm.powerlineVisible}})],1),(_vm.powerlineVisible)?_c('div',_vm._l((_vm.powerlines),function(powerline){return _c('DeviceButton',{key:powerline.id,attrs:{"device":powerline,"widget-list":_vm.scatWidgetList},on:{"widget-selected":_vm.selectWidget}})}),1):_vm._e()]),_c('div',{staticClass:"devices-block public"},[_c('p',{staticClass:"devices-title"},[_vm._v(" "+_vm._s(_vm.$t("NEW_DASHBOARD.SKAT_DEVICES"))+" "),_c('VisibleToggleButton',{attrs:{"set-visible":_vm.toggleSkatDevicesVisibility,"is-visible":_vm.skatDevicesVisible}})],1),(_vm.skatDevicesVisible)?_c('div',_vm._l((_vm.devices.filter(
          (device) => device.typeId === '297e9ce2-9134-4004-b67f-21c1683aaede'
        )),function(device){return _c('DeviceButton',{key:device.id,attrs:{"device":device,"widget-list":_vm.scatDevicesWidgetList},on:{"widget-selected":_vm.selectWidget,"addTemplate":function($event){return _vm.$emit('addTemplate', $event)},"deleteTemplate":function($event){return _vm.$emit('deleteTemplate', $event)},"deleteDevice":function($event){return _vm.$emit('deleteDevice', $event)}}})}),1):_vm._e()])]:[_c('div',{staticClass:"devices-block"},[_c('p',{staticClass:"devices-title"},[_vm._v(" "+_vm._s(_vm.$t("NEW_DASHBOARD.MY_DEVICES"))+" "),_c('VisibleToggleButton',{attrs:{"set-visible":_vm.toggleMyDevicesVisibility,"is-visible":_vm.myDevicesVisible}})],1),(_vm.myDevicesVisible)?_c('div',_vm._l((_vm.devices.filter(
          (device) =>
            !device.publicAvailable &&
            device.typeId !== '297e9ce2-9134-4004-b67f-21c1683aaede'
        )),function(device){return _c('DeviceButton',{key:device.id,attrs:{"device":device,"widget-list":_vm.widgetList,"canAddTemplate":true},on:{"widget-selected":_vm.selectWidget,"addTemplate":function($event){return _vm.$emit('addTemplate', $event)},"deleteTemplate":function($event){return _vm.$emit('deleteTemplate', $event)}}})}),1):_vm._e()]),_c('div',{staticClass:"devices-block public"},[_c('p',{staticClass:"devices-title"},[_vm._v(" "+_vm._s(_vm.$t("NEW_DASHBOARD.PUBLIC_DEVICES"))+" "),_c('VisibleToggleButton',{attrs:{"set-visible":_vm.togglePublicDevicesVisibility,"is-visible":_vm.publicDevicesVisible}})],1),(_vm.publicDevicesVisible)?_c('div',_vm._l((_vm.devices.filter((device) => device.publicAvailable)),function(device){return _c('DeviceButton',{key:device.id,attrs:{"device":device,"widget-list":_vm.widgetList,"canAddTemplate":true},on:{"widget-selected":_vm.selectWidget,"addTemplate":function($event){return _vm.$emit('addTemplate', $event)},"deleteTemplate":function($event){return _vm.$emit('deleteTemplate', $event)},"deleteDevice":function($event){return _vm.$emit('deleteDevice', $event)}}})}),1):_vm._e()])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }