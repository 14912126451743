<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-9">
            <b-form-input size="sm" aria-label="Name" :placeholder="$t('COMMON.NAME')" v-model="filterName"
              trim></b-form-input>

            <b-form-select v-model="filterTeamType" :options="teamTypes" size="sm">
              <template v-slot:first>
                <option value="" disabled>
                  {{ $t("TEAMS.SELECT_TEAM_TYPE") }}
                </option>
              </template>
            </b-form-select>

            <b-form-select v-model="filterCountry" :options="countries" size="sm">
              <template v-slot:first>
                <option value="" disabled>
                  {{ $t("TEAMS.SELECT_COUNTRY") }}
                </option>
              </template>
            </b-form-select>

            <CompactSuggestion ref="userSuggestionProcessorRef" :serializer="(s) => userSuggestionFormatter(s)"
              size="sm" v-model="filterOwnerId" :placeholder="$t('TEAMS.OWNER')"
              suggestion-processor="userSuggestionProcessor">
            </CompactSuggestion>

            <CompactSuggestion ref="tariffSuggestionProcessorRef" suggestion-processor="tariffSuggestionProcessor"
              size="sm" v-model="filterTariffId" :placeholder="$t('TEAMS.TARIFF')">
            </CompactSuggestion>

            <b-input-group-append>
              <b-button size="sm" text="Search" variant="success" @click="onFilterSearch">{{ $t("COMMON.SEARCH")
                }}</b-button>
              <b-button size="sm" text="Clear" variant="secondary" @click="onFiltersClear">{{ $t("COMMON.CLEAR")
                }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table id="table" striped bordered :items="response.data" @sort-changed="sortingChanged" no-local-sorting
          responsive selectable select-mode="single" :fields="fields" @row-selected="onRowsSelected"></b-table>

        <b-dropdown id="dropdown-show-by" :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage" class="m-md-2" offset="-50"
          style="float: right">
          <b-dropdown-item v-for="count in showPerPageArray" :key="count" @click="showPerPageChanged(count)">{{ count
            }}</b-dropdown-item>
        </b-dropdown>

        <div v-bind:style="response.data.length === 0 || itemsTotal < perPage
          ? 'display:none;'
          : null
          ">
          <b-pagination v-model="currentPage" :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null" :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate" :size="!calculate ? 'lg' : null" :per-page="perPage" @change="onPageSelected"
            :total-rows="itemsTotal" aria-controls="table">
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>

    <b-modal id="modal-delete-confirmation" :title="$t('COMMON.CONFIRMATION')" @ok="onDelete">
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedItem.name,
          })
        }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";
import CompactSuggestion from "../component/CompactSuggestion";

export default {
  name: "teams",
  mixins: [SuggestionProcessorMixin],
  components: { CompactSuggestion },
  data() {
    return {
      fields: [
        {
          key: "id",
          formatter: Common.idObjectFormatter,
          sortingKey: "el.id",
          sortable: false,
        },
        {
          key: "name",
          label: this.$t("COMMON.NAME"),
          sortingKey: "el.name",
          sortable: true,
        },
        {
          key: "teamTypeId",
          label: this.$t("COMMON.TYPE"),
          formatter: this.typeFormatter,
          sortingKey: "el.teamType.id",
          sortable: true,
        },
        {
          key: "countryId",
          label: this.$t("TEAMS.COUNTRY"),
          formatter: this.countryFormatter,
          sortingKey: "el.country.id",
          sortable: true,
        },
        {
          key: "tariffName",
          label: this.$t("TEAMS.TARIFF"),
          sortingKey: "el.tariff.id",
          sortable: true,
        },
      ],

      filterName: "",

      response: {
        data: [],
        totalCount: 1,
      },
      showPerPageArray: [10, 50, 100],

      getList: {
        resource: "/api/team",
        requestType: "GET",
        requestParams: { enrich: true },
      },

      currentPage: 1, // page numbers indexed from 1
      perPage: 10,

      showTableOverlay: true,

      errors: [],
      calculate: false,
      selectedItem: null,

      teamTypes: [],
      teamTypesRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.TeamType",
        requestType: "GET",
        requestParams: {},
      },
      filterTeamType: "",

      countries: [],
      countriesRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.Country",
        requestType: "GET",
        requestParams: {},
      },
      filterCountry: "",
      filterOwnerId: null,
      filterTariffId: null,
      teamTypesMap: {},
      countryMap: {},
    };
  },

  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
  },

  mounted() {
    let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.TEAMS") }]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("ACTIONS.CREATE_NEW"),
        action: this.onCreateNewAction,
        icon: "fas fa-plus",
      },
      {
        label: this.$t("ACTIONS.EDIT"),
        action: this.onEditAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-edit",
      },
      {
        label: this.$t("ACTIONS.DELETE"),
        action: this.onDeleteAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-trash-alt",
      },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
    ]);
  },

  created() {
    this.getList.requestParams.size = this.perPage;
    this.loadTeamTypes();
    this.loadCountries();
    this.loadList();
  },

  methods: {
    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = {
            data: response.content,
            totalCount: response.totalElements
          };
          this.currentPage = this.response.empty
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    loadTeamTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.teamTypesRequest)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.teamTypesMap[response[i].id] = response[i];
          }
          for (const value of response) {
            this.teamTypes.push({ value: value.id, text: value.nameLocalized });
          }
        })
        .catch(this.onError);
    },

    loadCountries: function () {
      this.$store
        .dispatch(API_REQUEST, this.countriesRequest)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.countryMap[response[i].id] = response[i];
          }
          for (const value of response) {
            this.countries.push({ value: value.id, text: value.nameLocalized });
          }
        })
        .catch(this.onError);
    },

    onTariffSelected: function (value) {
      this.selectedTariff = value.id;
    },

    onPageSelected: function (index) {
      this.getList.requestParams.page = (index - 1);
      this.loadList();
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    onCreateNewAction: function () {
      this.$router.push({ name: "team" });
    },

    onDeleteAction: function () {
      this.$bvModal.show("modal-delete-confirmation");
    },

    onEditAction: function () {
      this.$router.push({ name: "team", params: { id: this.selectedItem.id } });
    },

    onReloadAction: function () {
      this.getList.requestParams.page = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onDelete: function () {
      const del = {
        resource: "/api/team/" + this.selectedItem.id + "/delete",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.page = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.page = 0;
      this.getList.requestParams.size = count;
      this.perPage = count;

      this.loadList();
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      let sortDir = ctx.sortDesc ? "desc" : "asc";
      let sortField = 'id';

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        sortField = field.sortingKey;
      } else {
        sortField = ctx.sortBy;
      }
      this.getList.requestParams.page = 0;
      this.getList.requestParams.sort = `${sortField},${sortDir}`;
      this.loadList();
    },

    onFilterSearch: function () {
      this.currentPage = 1;
      this.getList.requestParams.page = 0;

      this.getList.requestParams["name"] =
        this.filterName.length > 0 ? this.filterName : null;
      this.getList.requestParams["teamTypeId"] =
        this.filterTeamType.length > 0 ? this.filterTeamType : null;
      this.getList.requestParams["countryId"] =
        this.filterCountry.length > 0 ? this.filterCountry : null;
      this.getList.requestParams["ownerId"] = this.filterOwnerId
        ? this.filterOwnerId
        : null;
      this.getList.requestParams["tariffId"] = this.filterTariffId
        ? this.filterTariffId
        : null;

      this.loadList();
    },

    onFiltersClear: function () {
      this.currentPage = 1;
      this.getList.requestParams.page = 0;
      this.getList.requestParams.size = this.perPage;

      delete this.getList.requestParams["name"];
      this.filterName = "";

      delete this.getList.requestParams["teamTypeId"];
      this.filterTeamType = "";

      delete this.getList.requestParams["countryId"];
      this.filterCountry = "";

      delete this.getList.requestParams["ownerId"];
      this.filterOwnerId = null;
      this.$refs.userSuggestionProcessorRef.clearValue();
      this.$refs.tariffSuggestionProcessorRef.clearValue();

      delete this.getList.requestParams["tariffId"];
      this.filterTariffId = null;

      this.loadList();
    },
    typeFormatter: function (value) {
      if (value && value in this.teamTypesMap)
        return this.teamTypesMap[value].nameLocalized;

      return "";
    },
    countryFormatter: function (value) {
      if (value && value in this.countryMap)
        return this.countryMap[value].nameLocalized;

      return "";
    },

    userSuggestionFormatter: function (s) {
      let str = s.name;
      if (s.description) {
        str = str + " (" + s.description + ")";
      }
      return str;
    },
  },
};
</script>
