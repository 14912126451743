import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/monitoring",
      component: () => import("@/view/layout/Layout.vue"),
      children: [
        {
          path: "/components",
          name: "components",
          component: () => import("@/view/pages/Components.vue"),
        },

        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),

          children: [
            {
              path: "/dashboard/share/:hash(.*)",
              name: "dashboard-share_widget",
              component: () => import("@/view/pages/ShareWidget.vue"),
            },
          ],
        },
        {
          path: "/dashboard-skat",
          name: "dashboard-skat",
          component: () => import("@/view/pages/DashboardSkat.vue"),
        },
        {
          path: "/sample_widget",
          name: "dashboard-sample_widget",
          component: () => import("@/view/pages/WidgetSampleEdit.vue"),
        },
        {
          path: "/segments",
          name: "segments",
          component: () => import("@/view/pages/Segments.vue"),
        },
        {
          path: "/payment-state/:state",
          name: "payment-state",
          component: () => import("@/view/pages/PaymentState.vue"),
        },
        {
          path: "/ml-models",
          name: "ml-models",
          component: () => import("@/view/pages/MLModels.vue"),
        },
        {
          path: "/ml-model/:id?",
          name: "ml-model",
          component: () => import("@/view/pages/MLModel.vue"),
        },
        {
          path: "/shop/data-package/:id",
          name: "buy-data-package",
          component: () => import("@/view/pages/BuyDataPackage.vue"),
        },
        {
          path: "/shop/ml-model/:id",
          name: "buy-model",
          component: () => import("@/view/pages/BuyMLModel.vue"),
        },
        // {
        //   path: "/payment-rules",
        //   name: "payment-rules",
        //   component: () => import("@/view/pages/PaymentRules.vue"),
        // },
        {
          path: "/dashboard/widget",
          name: "dashboard-widget",
          component: () => import("@/view/pages/DashboardWidgetEditor.vue"),
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/Users.vue"),
        },
        {
          path: "/notifications",
          name: "notifications",
          component: () => import("@/view/pages/Notifications.vue"),
        },
        {
          path: "/personal-notifications",
          name: "personal-notifications",
          component: () => import("@/view/pages/PersonalNotifications.vue"),
        },
        {
          path: "/personal-notifications/:id",
          name: "personal-notification",
          component: () => import("@/view/pages/PersonalNotification.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/Profile.vue"),
        },
        {
          path: "/discounts",
          name: "discounts",
          component: () => import("@/view/pages/Discounts.vue"),
        },
        {
          path: "/discount/:id?",
          name: "discount",
          component: () => import("@/view/pages/Discount.vue"),
        },
        {
          path: "/products",
          name: "products",
          component: () => import("@/view/pages/Products.vue"),
        },
        {
          path: "/product/:id?",
          name: "product",
          component: () => import("@/view/pages/Product.vue"),
        },
        {
          path: "/orders",
          name: "orders",
          component: () => import("@/view/pages/Orders.vue"),
        },
        {
          path: "/order/:id?",
          name: "order",
          component: () => import("@/view/pages/Order.vue"),
        },
        {
          path: "/passphrase-types",
          name: "passphrase-types",
          component: () => import("@/view/pages/PassphraseTypes.vue"),
        },
        {
          path: "/passphrase-type/:id?",
          name: "passphrasetype",
          component: () => import("@/view/pages/PassphraseType.vue"),
        },
        {
          path: "/tasks",
          name: "tasks",
          component: () => import("@/view/pages/Tasks.vue"),
        },
        {
          path: "/tariffs",
          name: "tariffs",
          component: () => import("@/view/pages/Tariffs.vue"),
        },
        {
          path: "/tariff/:id?",
          name: "tariff",
          component: () => import("@/view/pages/Tariff.vue"),
        },
        {
          path: "/feedbacks",
          name: "feedbacks",
          component: () => import("@/view/pages/Feedbacks.vue"),
        },
        {
          path: "/feedback/:id?",
          name: "feedback",
          component: () => import("@/view/pages/Feedback.vue"),
        },
        {
          path: "/task-execution-log/:id",
          name: "task-execution-log",
          component: () => import("@/view/pages/TaskExecutionLog.vue"),
        },
        {
          path: "/task/:id?",
          name: "task",
          component: () => import("@/view/pages/Task.vue"),
        },
        {
          path: "/user/:id?",
          name: "user",
          component: () => import("@/view/pages/User.vue"),
        },
        {
          path: "/properties",
          name: "properties",
          component: () => import("@/view/pages/Properties.vue"),
        },
        {
          path: "/property/:id?",
          name: "property",
          component: () => import("@/view/pages/Property.vue"),
        },
        {
          path: "/templates",
          name: "templates",
          component: () => import("@/view/pages/Templates.vue"),
        },
        {
          path: "/template/:id?",
          name: "template",
          component: () => import("@/view/pages/Template.vue"),
        },
        {
          path: "/roles",
          name: "roles",
          component: () => import("@/view/pages/Roles.vue"),
        },
        {
          path: "/role/:id?",
          name: "role",
          component: () => import("@/view/pages/Role.vue"),
        },
        {
          path: "/news/:sectionId",
          name: "news",
          component: () => import("@/view/pages/News.vue"),
        },
        {
          path: "/news-element/:sectionId/:id?",
          name: "news-element",
          component: () => import("@/view/pages/NewsElement.vue"),
        },
        {
          path: "/accounts",
          name: "accounts",
          component: () => import("@/view/pages/Accounts.vue"),
        },
        {
          path: "/transactions",
          name: "transactions",
          component: () => import("@/view/pages/Transactions.vue"),
        },
        {
          path: "/payments",
          name: "payments",
          component: () => import("@/view/pages/Payments.vue"),
        },
        {
          path: "/payment",
          name: "payment",
          component: () => import("@/view/pages/Payment.vue"),
        },
        {
          path: "/tokens",
          name: "tokens",
          component: () => import("@/view/pages/Tokens.vue"),
        },
        {
          path: "/token/:id?",
          name: "token",
          component: () => import("@/view/pages/Token.vue"),
        },
        {
          path: "/device-types",
          name: "device-types",
          component: () => import("@/view/pages/DeviceTypes.vue"),
        },
        {
          path: "/device-type/:id?",
          name: "device-type",
          component: () => import("@/view/pages/DeviceType.vue"),
        },
        {
          path: "/monitoring/:id?",
          name: "monitoring",
          component: () => import("@/view/pages/Monitoring.vue"),
        },
        {
          path: "/parameters",
          name: "parameters",
          component: () => import("@/view/pages/Parameters.vue"),
        },
        {
          path: "/parameter/:id?",
          name: "parameter",
          component: () => import("@/view/pages/Parameter.vue"),
        },
        {
          path: "/teams",
          name: "teams",
          component: () => import("@/view/pages/Teams.vue"),
        },
        {
          path: "/team/:id?",
          name: "team",
          component: () => import("@/view/pages/Team.vue"),
        },
        {
          path: "/checkout/:id",
          name: "checkout",
          component: () => import("@/view/pages/Checkout.vue"),
        },
        {
          path: "/devices",
          name: "devices",
          component: () => import("@/view/pages/Devices.vue"),
        },
        {
          path: "/skat",
          name: "skat",
          component: () => import("@/view/pages/SkatDevices.vue"),
        },
        {
          path: "/powerlines",
          name: "powerlines",
          component: () => import("@/view/pages/Powerlines.vue"),
        },
        {
          path: "/powerline/create",
          name: "powerline-create",
          component: () => import("@/view/pages/Powerline.vue"),
        },
        {
          path: "/powerline/:id",
          name: "powerline-edit",
          component: () => import("@/view/pages/Powerline.vue"),
        },
        {
          path: "/device/:id?",
          name: "device",
          component: () => import("@/view/pages/Device.vue"),
        },
        {
          path: "/catalogues",
          name: "catalogues",
          component: () => import("@/view/pages/Catalogues.vue"),
        },
        {
          path: "/catalogue/:id?",
          name: "catalogue",
          component: () => import("@/view/pages/Catalogue.vue"),
        },
        {
          path: "/messages",
          name: "messages",
          component: () => import("@/view/pages/Messages.vue"),
        },
        {
          path: "/data-packages",
          name: "data-packages",
          component: () => import("@/view/pages/DataPackages.vue"),
        },
        {
          path: "/data-package/:id?",
          name: "data-package",
          component: () => import("@/view/pages/DataPackage.vue"),
        },
        {
          path: "/reports",
          name: "reports",
          component: () => import("@/view/pages/Reports.vue"),
        },
        {
          path: "/logs",
          name: "logs",
          component: () => import("@/view/pages/Logs.vue"),
        },
        {
          path: "/api",
          name: "api",
          component: () => import("@/view/pages/Api.vue"),
        },
        {
          path: "/import-data",
          name: "import-data",
          component: () => import("@/view/pages/ImportData.vue"),
        },
        // {
        //   path: "/shop",
        //   name: "shop",
        //   component: () => import("@/view/pages/DataShop.vue"),
        // },
        {
          path: "/report/:id?",
          name: "report",
          component: () => import("@/view/pages/Report.vue"),
        },
        {
          path: "/units",
          name: "units",
          component: () => import("@/view/pages/Units.vue"),
        },
        {
          path: "/skat-device/:id?",
          name: "scat-device",
          component: () => import("@/view/pages/ScatDevice.vue"),
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login-1"),
        },
        {
          name: "register",
          path: "/register/:invitationId?",
          component: () => import("@/view/pages/auth/Login-1"),
        },
        {
          name: "repair",
          path: "/repair",
          component: () => import("@/view/pages/auth/Login-1"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
