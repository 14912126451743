<template>
  <b-container fluid class="m-0 p-0 mb-2">
    <b-row>
      <b-col sm="12" md="12" lg="2">
        <div>
          <div class="accordion" role="tablist">
            <DeviceWidgetSelector :path="path" :devices="devices.data" :catalogue="catalogue.data"
              :powerlines="powerlines" @widget-selected="$emit('add-widget', $event)"
              @addTemplate="$emit('addTemplate', $event)" @deleteTemplate="$emit('deleteTemplate', $event)"
              @deleteDevice="$emit('deleteDevice', $event)" />
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="12" lg="10">
        <div class="mt-4 px-0">
          <DashboardSkatGrid v-if="path === 'skat'" :devices="devices.data" :layouts="layouts"
            @update-widgets="$emit('update-widgets', $event)" @save-widgets="$emit('save-widgets')"
            @remove-widget="$emit('remove-widget', $event)" @add-widget="$emit('add-widget', $event)"
            @update-widget="$emit('update-widget', $event)" @layout-ready="$emit('layout-ready', $event)"
            @layout-updated="$emit('layout-updated', $event)" />
          <DashboardGrid v-else :devices="devices.data" :layouts="layouts"
            @update-widgets="$emit('update-widgets', $event)" @save-widgets="$emit('save-widgets')"
            @remove-widget="$emit('remove-widget', $event)" @add-widget="$emit('add-widget', $event)"
            @update-widget="$emit('update-widget', $event)" @layout-ready="$emit('layout-ready', $event)"
            @layout-updated="$emit('layout-updated', $event)" />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DashboardGrid from "./DashboardGrid.vue";
import DashboardSkatGrid from "./DashboardSkatGrid.vue";
import DeviceWidgetSelector from "@/view/content/new_dashboard/dashboard-sidebar/WidgetSelector";

export default {
  components: {
    DeviceWidgetSelector,
    DashboardGrid,
    DashboardSkatGrid,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    devices: {
      type: Object,
      required: true,
      default: () => ({
        data: []
      })
    },
    catalogue: {
      type: Object,
      required: true,
    },
    powerlines: {
      type: Array,
      required: true,
      default: () => [],
    },
    widgets: {
      type: Array,
      required: true,
    },
    layouts: {
      type: Object,
      required: true,
      default: () => ({
        desktop: [],
        tablet: [],
        mobile: []
      })
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showTableOverlay: false,
    };
  },
  computed: {
    currentLayout() {
      console.log(this.layouts, "layouts");
      return null


    },
  },
};
</script>

<style>
/* .dashboard-container {
  display: flex;
  background-color: rgba(238, 240, 248, 1);
  margin: 0;
} */

.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-control {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  justify-content: space-between;
}

/* .sidebar {
  min-width: 16%;
  height: 83vh;
  padding: 1rem;
  position: sticky;
  bottom: 0;
  left: 0;
  top: 0;
  overflow-y: auto;
} */

.device-header {
  cursor: pointer;
  font-weight: bold;
  padding: 10px 0;
}

.content {
  flex-grow: 1;
  overflow-y: scroll;

  /* position: relative; */
  height: 82vh;
  background-color: rgba(238, 240, 248, 1);
}

.content {
  scrollbar-width: 2px;
  /* -ms-overflow-style: none;  */
}

.content::-webkit-scrollbar {
  /* display: none; */
}

.no-widgets {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid-item-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.1);
  background-color: #ffffff;
  border: 1px solid #f2f3f8;
  border-radius: 5px;
  margin: 0;
  height: 100%;
  padding: 20px;
}

.widget-content {
  background: #fff;
  border: 1px solid #dee2e6;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.chart-container,
.table-container {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.chart {
  position: relative;
  height: 100%;
  width: 100%;
}

.device-button {
  min-height: 38px;
  text-align: center;
  width: 100%;
  background-color: #fff;
  border-radius: 7px;
  color: #000;
  padding: 5px 5px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  letter-spacing: -0.01em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.device-button:hover {
  background: linear-gradient(138.26deg,
      #57ede4 -5.68%,
      #64cff2 103.94%,
      #6fb5fe 166.45%);
  color: white;
}

b-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
