import ApiService from "@/core/services/api.service";
import * as _ from "lodash";
import Vue from "vue";

export async function loadSettings(teamId, dashboardType) {
  const path = `/platform/api/setting/${teamId}_dashboard${dashboardType ? `_${dashboardType}` : ""}`;
  const { data } = await ApiService.query(path);
  if (_.get(data, "value")) {
    return JSON.parse(data.value);
  }
  return null;
}

export async function storeSettings(data, dashboardType) {
  const path = `/platform/api/setting/${ApiService.teamId}_dashboard${dashboardType ? `_${dashboardType}` : ""}`;
  try {
    await ApiService.post(path + "/save", { value: JSON.stringify(data) });
  } catch (e) {
    Vue["$log"].error(e);
  }
}
