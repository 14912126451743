<template>
  <div>
    <button v-b-toggle="'accordion-' + device.id" class="device-button">
      <img src="media/svg/devices/device.svg" alt="" class="device-icon" />
      {{ device.name.length > 20? device.name.substr(0, 20) + '...': device.name }}
      <span>
        <b-icon-chevron-down v-if="!isCollapsed" />
        <b-icon-chevron-up v-else />
      </span>
    </button>
    <b-collapse
      :id="'accordion-' + device.id"
      accordion="my-accordion"
      role="tabpanel"
      @show="isCollapsed = true"
      @hide="isCollapsed = false"
    >
      <div class="collapse-list">
        <ul v-if="device.available_widgets.length > 0">
          <WidgetButton
            v-for="widget in device.available_widgets"
            :key="widget.id + '-' + device.id"
            :widget="{ ...widgetList[widget.type], ...widget, name: widget.isCustom? widget.name :  $t(widgetList[widget.type]?.name)}"
            :icon="getWidgetIcon(widget.type)"
            :device-id="device.id"
            @widget-selected="$emit('widget-selected', $event)"
            @deleteTemplate="$emit('deleteTemplate', $event)"
          />
        </ul>
        <p class="not-widgets" v-if="!!!device.available_widgets.length">
          {{$t('NEW_DASHBOARD.NO_WIDGETS_AVAILABLE')}}
        </p>
        <AddingTemplate
        v-if="canAddTemplate"
          :deviceId="device?.id"
          @addTemplate="$emit('addTemplate', $event)"
        >
          <template #open-modal-button="{ openModal }">
            <WidgetButton
              :widget="{ name: $t('NEW_DASHBOARD.NEW_TEMPLATE'), type: 'add_widget_button' }"
              icon="/media/svg/buttons/add_template_icon.svg"
              :device-id="device.id"
              @widget-selected="openModal()"
            />
          </template>
        </AddingTemplate>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import WidgetButton from "./WidgetAddButton.vue";
import AddingTemplate from "../components/AddingTemplate";

export default {
  components: {
    WidgetButton,
    AddingTemplate,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    canAddTemplate: {
      type: Boolean,
      default: false,
    },
    widgetList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    getWidgetIcon(code) {
      return this.widgetList[code]?.svg || "";
    },
  },
};
</script>

<style>
.device-button {
  min-height: 38px;
  text-align: center;
  width: 100%;
  background-color: #fff;
  border-radius: 7px;
  color: #000;
  padding: 5px 5px;
  margin-top: 12px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  letter-spacing: -0.01em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.device-button:hover {
  background: linear-gradient(
    138.26deg,
    #57ede4 -5.68%,
    #64cff2 103.94%,
    #6fb5fe 166.45%
  );
  color: white;
}
.device-icon {
  vertical-align: middle;
  border-style: none;
  background: linear-gradient(
    138.26deg,
    #57ede4 -5.68%,
    #64cff2 103.94%,
    #6fb5fe 166.45%
  );
  padding: 5px;
  border-radius: 8px;
}
.collapse-list {
  background-color: transparent;
}
.not-widgets {
  color: #ccc;
  margin-top: 10px;
}

.button {
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.2s;
  opacity: 0.8;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid #64cff2;

  background-color: #fff;

  color: #0abbf7;
}
.button:hover {
  background-color: #0abbf7;
  border-color: #64cff2;
  color: #fff;
}
</style>
