<template>
  <div>
    <MeasurementsList v-if="!selectedSensorId" ref="list" :widget="widget" :start-date="startDate" :end-date="endDate"
      @select="handleSelect" :filters="filters" :pagination="pagination" @totalCountChanged="onTotalCountChanged"
      @loading="onLoading" />
    <MeasurementDetail v-if="selectedSensorId" :sensor-id="selectedSensorId" :widget="widget" @back="handleSelect(null)"
      @loading="onLoading" />
  </div>
</template>

<script>
import MeasurementsList from "./MeasurementsList.vue";
import MeasurementDetail from "./MeasurementDetail.vue";

export default {
  components: {
    MeasurementsList,
    MeasurementDetail
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    startDate: {
      type: Date,
      required: true
    },
    endDate: {
      type: Date,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selectedSensorId: null,
    };
  },
  methods: {
    fetchData(
      startDate = this.startDate,
      endDate = this.endDate
    ) {
      this.$refs.list.fetchData(startDate, endDate);
    },
    onLoading(ev) {
      this.$emit('loading', ev);
    },
    handleSelect(sensorId) {
      this.selectedSensorId = sensorId;
      this.$emit('sensorSelected', sensorId);

    },
    onTotalCountChanged(totalCount) {
      this.$emit('totalCountChanged', totalCount);
    }

  }
};
</script>
